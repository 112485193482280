import { format, parseISO } from 'date-fns'
import * as React from 'react'
import { Helmet } from 'react-helmet-async'
import { useLocation, useSearchParams } from 'react-router-dom'
import * as Api from 'src/api'
import { LogIcon } from 'src/assets/icons/customIcons/LogIcon'
import { useApi } from 'src/helpers/hooks'
import { useAuthenticatedHeaders } from 'src/hooks/auth/app'
import { useDateLocale, useTranslatable } from 'src/hooks/locale/utils'
import { Section } from 'src/tailwind/components/Section'
import * as Table from 'src/tailwind/components/Table'
import { ErrorBoundary } from 'src/views/components/Error'
import ErrorElement from 'src/views/components/ErrorElement'
import Loader from 'src/views/components/Loader'
import Pagination from 'src/views/components/Pagination'

export default function ProfileAuthorizationLogPage(): JSX.Element | null {
  const t = useTranslatable()

  return (
    <>
      <Helmet title={t('course:my_courses')} />
      <React.Suspense fallback={<Loader className="m-auto flex" />}>
        <ErrorBoundary errorElement={<ErrorElement />}>
          <PageContent />
        </ErrorBoundary>
      </React.Suspense>
    </>
  )
}

function PageContent(): JSX.Element | null {
  const t = useTranslatable()
  const dateLocale = useDateLocale()
  const headers = useAuthenticatedHeaders()
  const [searchParams] = useSearchParams()
  const page = searchParams.get('page')
  const { pathname } = useLocation()
  const perPage = searchParams.get('perPage')
  const { data } = useApi({
    endpoint: Api.getUserAuthLog,
    params: React.useMemo(
      () => ({
        headers,
        query: {
          page: Number(page) ?? 1,
          perPage: Number(perPage),
        },
      }),
      [headers, page, perPage]
    ),
  })
  if (data.data == null) {
    return <Loader className="flex" style={{ margin: 'auto' }} />
  }

  const pagination = <Pagination pagination={data.meta.pagination} />

  const breadcrumbsItems = [{ page: `${t('system:authorization_log')}`, path: pathname }]

  return (
    <Section title={t('system:authorization_log')} icon={<LogIcon />} breadcrubms={breadcrumbsItems}>
      {pagination}
      <Table.Table>
        <Table.Thead>
          <Table.Tr>
            <Table.Th>{t('common:date')}</Table.Th>
            <Table.Th scope="col">{t('system:ip_address')}</Table.Th>
            <Table.Th scope="col">{t('system:user_agent')}</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {data.data.map((item) => (
            <Table.Tr key={item.id}>
              <Table.Td>{format(parseISO(item.createdAt), 'd MMMM yyyy, HH:mm', { locale: dateLocale })}</Table.Td>
              <Table.Td>{item.ip}</Table.Td>
              <Table.Td>{item.userAgent}</Table.Td>
            </Table.Tr>
          ))}
        </Table.Tbody>
      </Table.Table>
      {pagination}
    </Section>
  )
}
