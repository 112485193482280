import { type FormikHelpers } from 'formik'
import React from 'react'
import { Helmet } from 'react-helmet-async'
import { toast } from 'react-toastify'
import * as Api from 'src/api'
import { useAuthenticatedHeaders } from 'src/hooks/auth/app'
import { useAuth } from 'src/hooks/auth/auth'
import { useTranslatable } from 'src/hooks/locale/utils'
import { Form } from 'src/views/components/forms/formik/Form'
import { FormError } from 'src/views/components/forms/formik/FormError'
import { FormSubmit } from 'src/views/components/forms/formik/FormSubmit'
import TextInput from 'src/views/components/forms/formik/TextInput'

interface FormikValues {
  mobileNumber: string
  mobileNumber2: string
  homeNumber: string
  actualAddress: string
}
export default function ProfileContactInformationPage(): JSX.Element | null {
  const t = useTranslatable()

  return (
    <>
      <Helmet title={t('person:contact_information_profile')} />
      <PageContent />
    </>
  )
}

function PageContent(): JSX.Element | null {
  const headers = useAuthenticatedHeaders()
  const [successfullySubmited, setSuccessfullySubmited] = React.useState(false)
  const auth = useAuth()
  const t = useTranslatable()
  const onSubmit = React.useCallback(
    async (values: FormikValues, formikHelpers: FormikHelpers<FormikValues>): Promise<void> => {
      await Api.patchUserContact({
        headers,
        body: {
          mobileNumber: values.mobileNumber,
          mobileNumber2: values.mobileNumber2,
          homeNumber: values.homeNumber,
          actualAddress: values.actualAddress,
        },
      })
      toast.success(t('common:updated_successfully'))
      setSuccessfullySubmited(true)
      formikHelpers.resetForm({ values })
    },
    [headers, t]
  )

  if (auth.state !== 'Authenticated') return null

  return (
    <div className="mt-6 w-full rounded-card bg-card px-9 pb-4 pt-9 xxs:px-3 xxs:pt-3 xs:px-3 xs:pt-3">
      <Form
        initialValues={{
          mobileNumber: auth.user.mobileNumber ?? '',
          mobileNumber2: auth.user.mobileNumber2 ?? '',
          homeNumber: auth.user.homeNumber ?? '',
          actualAddress: auth.user.actualAddress ?? '',
        }}
        // enableReinitialize
        onSubmit={onSubmit}
        isConfirmable={!successfullySubmited}
      >
        <FormError />
        <TextInput
          disabled
          isHorizontal
          fixedLabelWidth
          rightAlign
          value={auth.user.email}
          type="text"
          label={t('person:e_mail')}
          name="email"
          className="flex"
        />
        <TextInput
          required
          isHorizontal
          rightAlign
          fixedLabelWidth
          type="text"
          label={`${t('person:phone_number')} 1`}
          name="mobileNumber"
          data-testid="ProfileContactInformationPage/mobileNumber"
        />
        <TextInput
          isHorizontal
          rightAlign
          fixedLabelWidth
          type="text"
          label={`${t('person:phone_number')} 2`}
          name="mobileNumber2"
          data-testid="ProfileContactInformationPage/mobileNumber2"
        />
        <TextInput
          required
          rightAlign
          isHorizontal
          fixedLabelWidth
          type="text"
          label={t('person:home_phone')}
          name="homeNumber"
          data-testid="ProfileContactInformationPage/homeNumber"
        />
        <TextInput
          required
          rightAlign
          isHorizontal
          fixedLabelWidth
          type="text"
          label={t('person:address')}
          name="actualAddress"
          data-testid="ProfileContactInformationPage/actualAddress"
        />
        <div className="flex w-full justify-end">
          <FormSubmit label={t('common:save')} data-testid="ProfileContactInformationPage/submit" />
        </div>
        <FormError />
      </Form>
    </div>
  )
}
