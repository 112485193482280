export function NoPicture(): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 32 32" data-testid="noPicture">
      <g id="nopic_icon" transform="translate(-676 -364.4)">
        <circle
          id="Ellipse_387"
          data-name="Ellipse 387"
          cx="16"
          cy="16"
          r="16"
          transform="translate(676 364.4)"
          fill="#e5e5e5"
        />
        <g id="Ellipse_388" data-name="Ellipse 388">
          <circle
            id="Ellipse_998"
            data-name="Ellipse 998"
            cx="7"
            cy="7"
            r="7"
            transform="translate(685 370.4)"
            fill="none"
          />
          <path
            id="Path_25068"
            data-name="Path 25068"
            d="M692,384.4a7,7,0,1,1,7-7A7.008,7.008,0,0,1,692,384.4Zm0-12a5,5,0,1,0,5,5A5.006,5.006,0,0,0,692,372.4Z"
            fill="gray"
          />
        </g>
        <path
          id="Path_25069"
          data-name="Path 25069"
          d="M692.359,382.418c-.12,0-.238-.018-.359-.018s-.239.015-.359.018a12,12,0,0,0-11.245,8.977,16.077,16.077,0,0,0,1.719,1.57,9.987,9.987,0,0,1,19.77,0,16.077,16.077,0,0,0,1.719-1.57A12,12,0,0,0,692.359,382.418Z"
          fill="gray"
        />
      </g>
    </svg>
  )
}
