import { type FormikHelpers } from 'formik'
import * as React from 'react'
import { Helmet } from 'react-helmet-async'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import * as Api from 'src/api'
import { EnvelopeIcon } from 'src/assets/icons/customIcons/Envelope'
import { NoPicture } from 'src/assets/icons/customIcons/noPicture'
import { useApi } from 'src/helpers/hooks'
import { useAuthenticatedHeaders } from 'src/hooks/auth/app'
import { useLocale } from 'src/hooks/locale/locale'
import { useTranslatable } from 'src/hooks/locale/utils'

import { Section } from 'src/tailwind/components/Section'
import { ErrorBoundary } from 'src/views/components/Error'
import ErrorElement from 'src/views/components/ErrorElement'
import { Form } from 'src/views/components/forms/formik/Form'
import { FormSubmit } from 'src/views/components/forms/formik/FormSubmit'
import TextInput from 'src/views/components/forms/formik/TextInput'
import TextareaInput from 'src/views/components/forms/formik/TextareaInput'
import * as Yup from 'yup'
import Loader from '../../../components/Loader'

interface FormikValues {
  readonly subject: string
  readonly body: string
}

export default function MessageComposePage(): JSX.Element | null {
  const t = useTranslatable()

  return (
    <>
      <Helmet title={t('message:messages_compose')} />
      <React.Suspense fallback={<Loader className="m-auto flex" />}>
        <ErrorBoundary errorElement={<ErrorElement />}>
          <PageContent />
        </ErrorBoundary>
      </React.Suspense>
    </>
  )
}

function PageContent(): JSX.Element | null {
  const [successfullySubmited, setSuccessfullySubmited] = React.useState(false)
  const { uid } = useParams()
  const t = useTranslatable()
  const headers = useAuthenticatedHeaders()
  const navigate = useNavigate()
  const locale = useLocale()
  const { pathname } = useLocation()

  const recipient = useApi({
    endpoint: Api.getUser,
    params: React.useMemo(
      () => ({
        headers,
        args: {
          uid: uid!,
        },
      }),
      [headers, uid]
    ),
  })

  const submit = React.useCallback(
    async ({ subject, body }: FormikValues, formikHelpers: FormikHelpers<FormikValues>): Promise<void> => {
      try {
        await Api.postMessages({
          headers,
          body: {
            recipients: [recipient.data.id],
            subject,
            body,
          },
        })

        toast.success(t('feedback:add_feedback'))
        formikHelpers.resetForm()
        setSuccessfullySubmited(true)
      } catch (error) {
        toast.error(t('error:an_error_occurred'))
      }
    },
    [headers, recipient.data.id, t]
  )
  React.useEffect(() => {
    if (successfullySubmited) {
      navigate(`/${locale}/messages/inbox`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successfullySubmited])

  const breadcrumbsItems = [
    { page: recipient.data.fullName, path: `/${locale}/users/${recipient.data.uid}` },
    { page: `${t('message:messages_compose')}`, path: pathname },
  ]

  return (
    <Section
      icon={
        <div className="w-[24px]">
          <EnvelopeIcon color="black" />
        </div>
      }
      title={t('message:messages_compose')}
      breadcrubms={breadcrumbsItems}
    >
      <div className="rounded-[12px] border border-borderGrey text-primaryTextColor">
        <Form
          initialValues={{
            subject: '',
            body: '',
          }}
          onSubmit={submit}
          validationSchema={Yup.object().shape({
            subject: Yup.string().required(t('message:subject_is_required')),
            body: Yup.string().required(t('message:body_is_required')),
          })}
          isConfirmable={!successfullySubmited}
        >
          <div className="flex items-center border-b border-borderGrey px-6 py-3">
            <span className="mr-[12px] font-bold">{t('message:recipient_s')}:</span>
            <span className="w-[32px]">
              <NoPicture />
            </span>
            <span className="ml-[12px] text-title"> {recipient.data.fullName}</span>
          </div>
          <div className="border-b border-borderGrey px-6 py-3">
            <TextInput isHorizontal type="text" name="subject" label={t('message:subject')} />
          </div>
          <div className="px-6">
            <TextareaInput name="body" className="!border-0" rows={7} required />
          </div>
          <FormSubmit
            label={t('message:send')}
            classNames={{
              root: 'w-full flex justify-end mt-4 mb-[16px] pr-6 border-t  border-borderGrey pt-3',
            }}
          />
        </Form>
      </div>
    </Section>
  )
}
