import { type FormikHelpers } from 'formik'
import * as React from 'react'
import { Helmet } from 'react-helmet-async'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import * as Api from 'src/api'
import { EmailIcon } from 'src/assets/icons/customIcons/Email'
import { useApi } from 'src/helpers/hooks'
import { useAuthenticatedHeaders } from 'src/hooks/auth/app'
import { useAuth } from 'src/hooks/auth/auth'
import { useLocale } from 'src/hooks/locale/locale'
import { useTranslatable } from 'src/hooks/locale/utils'
import { Section } from 'src/tailwind/components/Section'
import { ErrorBoundary } from 'src/views/components/Error'
import ErrorElement from 'src/views/components/ErrorElement'
import { Form } from 'src/views/components/forms/formik/Form'
import { FormSubmit } from 'src/views/components/forms/formik/FormSubmit'
import TextInput from 'src/views/components/forms/formik/TextInput'
import TextareaInput from 'src/views/components/forms/formik/TextareaInput'
import * as Yup from 'yup'
import Loader from '../../../components/Loader'
import UserPhoto from '../../../components/UserPhoto'

interface FormikValues {
  readonly subject: string
  readonly body: string
}

export default function MessageReplyPage(): JSX.Element | null {
  const t = useTranslatable()

  return (
    <>
      <Helmet title={t('message:messages_compose')} />
      <React.Suspense fallback={<Loader className="m-auto flex" style={{ height: '100vh' }} />}>
        <ErrorBoundary errorElement={<ErrorElement />}>
          <PageContent />
        </ErrorBoundary>
      </React.Suspense>
    </>
  )
}

function PageContent(): JSX.Element | null {
  const [successfullySubmited, setSuccessfullySubmited] = React.useState(false)
  const headers = useAuthenticatedHeaders()
  const { messageId, semesterId } = useParams()
  const auth = useAuth()
  const t = useTranslatable()
  const locale = useLocale()
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const message = useApi({
    endpoint: Api.getMessage,
    params: React.useMemo(
      () => ({
        headers,
        args: {
          messageId: messageId!,
        },
        query: {
          semId: semesterId!,
        },
      }),
      [headers, messageId, semesterId]
    ),
  })

  React.useEffect(() => {
    if (successfullySubmited) {
      navigate(`/${locale}/messages/inbox`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successfullySubmited])
  if (auth.state !== 'Authenticated') return null

  const isInbox = message.data.receiver?.id === auth.user.id
  const otherUser = isInbox ? message.data.sender! : message.data.receiver!

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const submit = React.useCallback(
    async ({ subject, body }: FormikValues, formikHelpers: FormikHelpers<FormikValues>): Promise<void> => {
      try {
        await Api.postMessages({
          headers,
          body: {
            replyMsgId: messageId,
            replyMsgSemId: semesterId,
            recipients: [otherUser.id],
            subject,
            body,
          },
        })

        toast.success(t('feedback:add_feedback'))
        formikHelpers.resetForm()
        setSuccessfullySubmited(true)
      } catch (error) {
        toast.error(t('error:an_error_occurred'))
      }
    },
    [headers, messageId, otherUser.id, semesterId, t]
  )

  const breadcrumbsItems = [
    { page: `${t('message:messages')}`, path: isInbox ? `/${locale}/messages/inbox` : `/${locale}/messages/outbox` },
    { page: message.data.subject, path: pathname },
    {
      page: `${t('message:send_message')}`,
      path: `/${locale}/messages/reply/${message.data.id}/${message.data.semId}`,
    },
  ]

  return (
    <Section title={t('message:messages_compose')} icon={<EmailIcon />} breadcrubms={breadcrumbsItems}>
      <div>
        <div className="mb-3 text-captionColor" dangerouslySetInnerHTML={{ __html: message.data.body }} />
      </div>
      <div className="rounded-lg border border-borderColor text-primaryTextColor">
        <Form
          initialValues={{ subject: message.data.subject ?? '', body: '' }}
          validationSchema={Yup.object().shape({
            subject: Yup.string().required(t('message:subject_is_required')),
            body: Yup.string().required(t('message:body_is_required')),
          })}
          onSubmit={submit}
          isConfirmable={!successfullySubmited}
        >
          <div className="mb-3 flex items-center border-b border-borderColor px-4 py-3">
            <label className="mr-3 text-bodyText font-bold">{t('message:recipient_s')}:</label>
            <div>
              <UserPhoto user={otherUser} />
              <Link to={`/${locale}/users/${otherUser.uid}`} className="ml-2 text-bodyText">
                {otherUser.fullName}
              </Link>
            </div>
          </div>
          <div className="border-b border-borderColor px-4 py-3">
            <TextInput name="subject" label={t('message:subject')} type="text" className="px-4 py-3" />
            <TextareaInput
              name="body"
              className="!border-0"
              placeholder={t('message:enter_the_message_text')}
              rows={7}
              required
            />
          </div>
          <FormSubmit
            label={t('message:send')}
            classNames={{
              root: 'flex justify-end my-3 mx-4 ',
            }}
          />
        </Form>
      </div>
    </Section>
  )
}
