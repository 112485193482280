export default function ZipIcon(): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" data-testid="zipIcon">
      <g id="doc_icon" transform="translate(1 7)">
        <rect
          id="Rectangle_4360"
          data-name="Rectangle 4360"
          width="24"
          height="24"
          transform="translate(-1 -7)"
          opacity="0"
        />
        <rect
          id="Rectangle_4250"
          data-name="Rectangle 4250"
          width="22"
          height="22"
          transform="translate(0 -6)"
          opacity="0"
        />
        <g id="Path_25073" data-name="Path 25073" transform="translate(2 -6)" fill="#f2f2f2">
          <path
            d="M 17.5 21.5 L 0.5 21.5 L 0.5 0.5 L 11.79290008544922 0.5 L 17.5 6.207099914550781 L 17.5 21.5 Z"
            stroke="none"
          />
          <path
            d="M 1 1 L 1 21 L 17 21 L 17 6.414219856262207 L 11.58578014373779 1 L 1 1 M 0 0 L 12 0 L 18 6 L 18 22 L 0 22 L 0 0 Z"
            stroke="none"
            fill="gray"
          />
        </g>
        <g id="Path_25072" data-name="Path 25072" transform="translate(14 -6)" fill="none">
          <path d="M-1,0H0L6,6V7H-1Z" stroke="none" />
          <path
            d="M 0 1.414219856262207 L 0 6 L 4.585780143737793 6 L 0 1.414219856262207 M -1 0 L 0 0 L 6 6 L 6 7 L -1 7 L -1 0 Z"
            stroke="none"
            fill="gray"
          />
        </g>
        <g
          id="Rectangle_4275"
          data-name="Rectangle 4275"
          transform="translate(0 4)"
          fill="#f2f2f2"
          stroke="gray"
          strokeWidth="1"
        >
          <rect width="22" height="9" stroke="none" />
          <rect x="0.5" y="0.5" width="21" height="8" fill="none" />
        </g>
        <path id="Path_25106" data-name="Path 25106" d="M.578,0H4V1H0Z" transform="translate(4 10)" fill="gray" />
        <path id="Path_25105" data-name="Path 25105" d="M0,0H4L3.359,1H0Z" transform="translate(4 6)" fill="gray" />
        <rect
          id="Rectangle_4371"
          data-name="Rectangle 4371"
          width="1"
          height="5"
          transform="translate(10 6)"
          fill="gray"
        />
        <path
          id="Path_25104"
          data-name="Path 25104"
          d="M2.769.031h1.2L1.2,5H0Z"
          transform="translate(4 6)"
          fill="gray"
        />
        <rect
          id="Rectangle_4388"
          data-name="Rectangle 4388"
          width="1"
          height="5"
          transform="translate(14 6)"
          fill="gray"
        />
        <g
          id="Rectangle_4387"
          data-name="Rectangle 4387"
          transform="translate(14 6)"
          fill="none"
          stroke="gray"
          strokeWidth="1"
        >
          <path d="M0,0H2A2,2,0,0,1,4,2V2A2,2,0,0,1,2,4H0A0,0,0,0,1,0,4V0A0,0,0,0,1,0,0Z" stroke="none" />
          <path
            d="M1,.5H2A1.5,1.5,0,0,1,3.5,2V2A1.5,1.5,0,0,1,2,3.5H1A.5.5,0,0,1,.5,3V1A.5.5,0,0,1,1,.5Z"
            fill="none"
          />
        </g>
      </g>
    </svg>
  )
}
