import {
  getSemestersActive,
  getUserSemestersAvailable,
  type getSemestersActiveOk,
  type getUserSemestersAvailableOk,
} from 'src/api'
import { useAnyHeaders, useAuthenticatedHeaders } from 'src/hooks/auth/app'
import useSWR from 'swr'

export function useAvailableSemesters(): getUserSemestersAvailableOk | null {
  const headers = useAuthenticatedHeaders()

  const response = useSWR(
    `getUserSemestersAvailable-${headers['Accept-Language']}-${headers['Profile-ID']}`,
    async () => {
      return await getUserSemestersAvailable({
        headers,
      })
    },
    {
      suspense: false,
    }
  )

  return response.data ?? null
}

export function useActiveSemester(): getSemestersActiveOk | null {
  const headers = useAnyHeaders()

  const response = useSWR(
    `getSemestersActive-${headers['Accept-Language']}-${headers['Profile-ID']}`,
    async () => {
      return await getSemestersActive({
        headers,
      })
    },
    {
      suspense: false,
    }
  )

  return response.data ?? null
}
