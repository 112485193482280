import * as React from 'react'
import type * as Api from 'src/api'
import { NoPicture } from 'src/assets/icons/customIcons/noPicture'

interface Props {
  readonly user?: Api.User
  readonly width?: number | string
  readonly height?: number | string
  readonly className?: string
}

export default function UserPhoto({ user, width = 24, height = 24, className }: Props): React.ReactElement | null {
  if (user == null) return null

  if (user.photoUrl != null && user.photoUrl.length > 0) {
    return (
      <img
        src={user.photoUrl}
        className={`rounded-full bg-white object-contain ${className ?? ''}`}
        style={{
          height: width,
          width: height,
        }}
        data-testid="userPhoto"
      />
    )
  }
  if (user.avatar != null) {
    return (
      <div
        dangerouslySetInnerHTML={{ __html: user.avatar }}
        className="user-avatar inline-block max-h-10 max-w-10 align-middle"
        data-testid="userAvatar"
      />
    )
  }

  return (
    <div className="inline-block w-8 align-middle">
      <NoPicture />
    </div>
  )
}
