interface Props {
  readonly width?: string
  readonly height?: string
}

export function PdfIcon({ width = '100%', height = '100%' }: Props): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" data-testid="pdfIcon">
      <g id="doc_icon" transform="translate(1 7)">
        <rect
          id="Rectangle_4360"
          data-name="Rectangle 4360"
          width="24"
          height="24"
          transform="translate(-1 -7)"
          opacity="0"
        />
        <rect
          id="Rectangle_4250"
          data-name="Rectangle 4250"
          width="22"
          height="22"
          transform="translate(0 -6)"
          opacity="0"
        />
        <path
          id="Subtraction_256"
          data-name="Subtraction 256"
          d="M17600-954h-18l0-3.5h1v2.5h16v-2.5h1v3.5Zm0-11.5h-1v-4.086l-5.41-5.415H17583v9.5h-1V-976h12l6,6v4.5Z"
          transform="translate(-17580 970)"
          fill="gray"
        />
        <path
          id="Path_25072"
          data-name="Path 25072"
          d="M0,1.414V6H4.586L0,1.414M-1,0H0L6,6V7H-1Z"
          transform="translate(14 -6)"
          fill="gray"
        />
        <path
          id="Rectangle_4275"
          data-name="Rectangle 4275"
          d="M1,1V8H21V1H1M0,0H22V9H0Z"
          transform="translate(0 4)"
          fill="gray"
        />
        <rect
          id="Rectangle_4361"
          data-name="Rectangle 4361"
          width="1"
          height="5"
          transform="translate(4 6)"
          fill="gray"
        />
        <rect
          id="Rectangle_4364"
          data-name="Rectangle 4364"
          width="1"
          height="5"
          transform="translate(14 6)"
          fill="gray"
        />
        <rect
          id="Rectangle_4365"
          data-name="Rectangle 4365"
          width="4"
          height="1"
          transform="translate(14 6)"
          fill="gray"
        />
        <rect
          id="Rectangle_4366"
          data-name="Rectangle 4366"
          width="3"
          height="1"
          transform="translate(14 8)"
          fill="gray"
        />
        <path
          id="Rectangle_4362"
          data-name="Rectangle 4362"
          d="M1,1V3H2A1,1,0,0,0,2,1H1M0,0H2A2,2,0,0,1,2,4H0Z"
          transform="translate(4 6)"
          fill="gray"
        />
        <path
          id="Rectangle_4363"
          data-name="Rectangle 4363"
          d="M1,1V4H2A1,1,0,0,0,3,3V2A1,1,0,0,0,2,1H1M0,0H2A2,2,0,0,1,4,2V3A2,2,0,0,1,2,5H0Z"
          transform="translate(9 6)"
          fill="gray"
        />
      </g>
    </svg>
  )
}
