export function UserIcon(): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="18"
      height="18"
      viewBox="0 0 18 18"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_4161"
            data-name="Rectangle 4161"
            width="16"
            height="14"
            transform="translate(301 564)"
            fill="gray"
            stroke="gray"
            strokeWidth="1"
          />
        </clipPath>
      </defs>
      <g id="user_icon" transform="translate(-299.999 -561)">
        <rect
          id="Rectangle_161"
          data-name="Rectangle 161"
          width="18"
          height="18"
          transform="translate(299.999 561)"
          fill="gray"
          opacity="0"
        />
        <rect
          id="Rectangle_304"
          data-name="Rectangle 304"
          width="16"
          height="16"
          transform="translate(301 562)"
          fill="gray"
          opacity="0"
        />
        <g
          id="Ellipse_969"
          data-name="Ellipse 969"
          transform="translate(302 571)"
          fill="none"
          stroke="gray"
          strokeWidth="1"
        >
          <circle cx="7" cy="7" r="7" stroke="none" />
          <circle cx="7" cy="7" r="6.5" fill="none" />
        </g>
        <g
          id="Ellipse_326"
          data-name="Ellipse 326"
          transform="translate(305 563)"
          fill="none"
          stroke="gray"
          strokeWidth="1"
        >
          <circle cx="4" cy="4" r="4" stroke="none" />
          <circle cx="4" cy="4" r="3.5" fill="none" />
        </g>
      </g>
    </svg>
  )
}
