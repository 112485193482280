import classnames from 'classnames'
import React from 'react'
import { Helmet } from 'react-helmet-async'
import { ErrorBoundary } from 'src/views/components/Error'
import ErrorElement from 'src/views/components/ErrorElement'
import Loader from 'src/views/components/Loader'

interface Props {
  readonly children: JSX.Element
  readonly className?: string
  readonly title?: string
}

export default function SuspenseWrapper({ children, className, title }: Props): JSX.Element {
  return (
    <>
      {title != null && <Helmet title={title} />}

      <React.Suspense fallback={<Loader className={classnames('mx-auto my-5 flex', { className })} />}>
        <ErrorBoundary errorElement={<ErrorElement />}>{children}</ErrorBoundary>
      </React.Suspense>
    </>
  )
}
