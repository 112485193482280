export default function ExcelIcon(): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" data-testid="excelIcon">
      <g id="doc_icon" transform="translate(1 7)">
        <rect
          id="Rectangle_4360"
          data-name="Rectangle 4360"
          width="24"
          height="24"
          transform="translate(-1 -7)"
          opacity="0"
        />
        <rect
          id="Rectangle_4250"
          data-name="Rectangle 4250"
          width="22"
          height="22"
          transform="translate(0 -6)"
          opacity="0"
        />
        <g id="Path_25073" data-name="Path 25073" transform="translate(2 -6)" fill="none">
          <path
            d="M 17.5 21.5 L 0.5 21.5 L 0.5 0.5 L 11.79290008544922 0.5 L 17.5 6.207099914550781 L 17.5 21.5 Z"
            stroke="none"
          />
          <path
            d="M 1 1 L 1 21 L 17 21 L 17 6.414219856262207 L 11.58578014373779 1 L 1 1 M 0 0 L 12 0 L 18 6 L 18 22 L 0 22 L 0 0 Z"
            stroke="none"
            fill="gray"
          />
        </g>
        <g id="Path_25072" data-name="Path 25072" transform="translate(14 -6)" fill="none">
          <path d="M-1,0H0L6,6V7H-1Z" stroke="none" />
          <path
            d="M 0 1.414219856262207 L 0 6 L 4.585780143737793 6 L 0 1.414219856262207 M -1 0 L 0 0 L 6 6 L 6 7 L -1 7 L -1 0 Z"
            stroke="none"
            fill="gray"
          />
        </g>
        <g
          id="Rectangle_4275"
          data-name="Rectangle 4275"
          transform="translate(0 4)"
          fill="none"
          stroke="gray"
          strokeWidth="1"
        >
          <rect width="22" height="9" stroke="none" />
          <rect x="0.5" y="0.5" width="21" height="8" fill="none" />
        </g>
        <path id="Path_25101" data-name="Path 25101" d="M3,0H4.2l-3,5H0Z" transform="translate(4 6)" fill="gray" />
        <path id="Path_25102" data-name="Path 25102" d="M1.2,0H0L3,5H4.2Z" transform="translate(4 6)" fill="gray" />
        <rect
          id="Rectangle_4371"
          data-name="Rectangle 4371"
          width="1"
          height="5"
          transform="translate(9 6)"
          fill="gray"
        />
        <path
          id="Union_37"
          data-name="Union 37"
          d="M17598-1112v-1h3v-1h-2v0a1,1,0,0,1-1-1v-1a1,1,0,0,1,1-1h3v1h-3v1h2v0a1,1,0,0,1,1,1v1a1,1,0,0,1-1,1Z"
          transform="translate(-17584 1123.002)"
          fill="gray"
        />
        <rect
          id="Rectangle_4372"
          data-name="Rectangle 4372"
          width="4"
          height="1"
          transform="translate(9 10)"
          fill="gray"
        />
      </g>
    </svg>
  )
}
