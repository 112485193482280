export default function TextFileIcon(): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" data-testid="textFileIcon">
      <g id="doc_icon" transform="translate(1 7)">
        <rect
          id="Rectangle_4360"
          data-name="Rectangle 4360"
          width="24"
          height="24"
          transform="translate(-1 -7)"
          opacity="0"
        />
        <rect
          id="Rectangle_4250"
          data-name="Rectangle 4250"
          width="22"
          height="22"
          transform="translate(0 -6)"
          opacity="0"
        />
        <g id="Path_25073" data-name="Path 25073" transform="translate(2 -6)" fill="#f2f2f2">
          <path
            d="M 17.5 21.5 L 0.5 21.5 L 0.5 0.5 L 11.79290008544922 0.5 L 17.5 6.207099914550781 L 17.5 21.5 Z"
            stroke="none"
          />
          <path
            d="M 1 1 L 1 21 L 17 21 L 17 6.414219856262207 L 11.58578014373779 1 L 1 1 M 0 0 L 12 0 L 18 6 L 18 22 L 0 22 L 0 0 Z"
            stroke="none"
            fill="gray"
          />
        </g>
        <g id="Path_25072" data-name="Path 25072" transform="translate(14 -6)" fill="none">
          <path d="M-1,0H0L6,6V7H-1Z" stroke="none" />
          <path
            d="M 0 1.414219856262207 L 0 6 L 4.585780143737793 6 L 0 1.414219856262207 M -1 0 L 0 0 L 6 6 L 6 7 L -1 7 L -1 0 Z"
            stroke="none"
            fill="gray"
          />
        </g>
        <g id="Group_284" data-name="Group 284" transform="translate(1)">
          <path
            id="Subtraction_250"
            data-name="Subtraction 250"
            d="M17591-1502h-1l0-1a2,2,0,0,1,2-2v1a1,1,0,0,0-1,1v1Z"
            transform="translate(-17585 1508)"
            fill="gray"
          />
          <path
            id="Subtraction_252"
            data-name="Subtraction 252"
            d="M17591-1502h-1l0-1a2,2,0,0,1,2-2v1a1,1,0,0,0-1,1v1Z"
            transform="translate(17596 -1496.996) rotate(180)"
            fill="gray"
          />
          <path
            id="Subtraction_253"
            data-name="Subtraction 253"
            d="M1,0H0L0,1A2,2,0,0,0,2,3v-1A1,1,0,0,1,1,1V0Z"
            transform="translate(6 10.004) rotate(180)"
            fill="gray"
          />
          <path
            id="Subtraction_251"
            data-name="Subtraction 251"
            d="M1,0H0L0,1A2,2,0,0,0,2,3v-1A1,1,0,0,1,1,1V0Z"
            transform="translate(4.998 9)"
            fill="gray"
          />
        </g>
        <g id="Group_285" data-name="Group 285" transform="translate(20 15.004) rotate(180)">
          <path
            id="Subtraction_250-2"
            data-name="Subtraction 250"
            d="M17591-1502h-1l0-1a2,2,0,0,1,2-2v1a1,1,0,0,0-1,1v1Z"
            transform="translate(-17585 1508)"
            fill="gray"
          />
          <path
            id="Subtraction_252-2"
            data-name="Subtraction 252"
            d="M17591-1502h-1l0-1a2,2,0,0,1,2-2v1a1,1,0,0,0-1,1v1Z"
            transform="translate(17596 -1496.996) rotate(180)"
            fill="gray"
          />
          <path
            id="Subtraction_253-2"
            data-name="Subtraction 253"
            d="M1,0H0L0,1A2,2,0,0,0,2,3v-1A1,1,0,0,1,1,1V0Z"
            transform="translate(6 10.004) rotate(180)"
            fill="gray"
          />
          <path
            id="Subtraction_251-2"
            data-name="Subtraction 251"
            d="M1,0H0L0,1A2,2,0,0,0,2,3v-1A1,1,0,0,1,1,1V0Z"
            transform="translate(4.998 9)"
            fill="gray"
          />
        </g>
        <rect
          id="Rectangle_4433"
          data-name="Rectangle 4433"
          width="1"
          height="1"
          transform="translate(10 7)"
          fill="gray"
        />
        <rect
          id="Rectangle_4434"
          data-name="Rectangle 4434"
          width="1"
          height="1"
          transform="translate(12 7)"
          fill="gray"
        />
        <rect
          id="Rectangle_4435"
          data-name="Rectangle 4435"
          width="1"
          height="1"
          transform="translate(8 7)"
          fill="gray"
        />
      </g>
    </svg>
  )
}
