import classNames from 'classnames'
import React from 'react'

interface Props {
  children: React.ReactNode | React.ReactNode[]
}

export const Card = React.forwardRef<HTMLDivElement, JSX.IntrinsicElements['div'] & Props>(function Card(
  { children, ...props },
  ref
) {
  return (
    <div {...props} ref={ref} className={classNames('w-full rounded-card bg-card', props.className)}>
      {children}
    </div>
  )
})
