import { Link } from 'react-router-dom'
import { useAuth } from 'src/hooks/auth/auth'
import { useLocale } from 'src/hooks/locale/locale'
import { useTranslatable } from 'src/hooks/locale/utils'

export default function NotFoundPage(): JSX.Element | null {
  const t = useTranslatable()
  const locale = useLocale()
  const auth = useAuth()

  return (
    <div
      className={'m-auto flex w-full items-center justify-center' + (auth.state === 'Authenticated' && 'max-w-[70vw]')}
    >
      <div className="page-not-found flex size-full items-center justify-center rounded-xl bg-card px-3 py-24 text-center">
        <div>
          <p className="text-9xl text-[#D9D9D9]">404</p>
          <p className="mt-2 text-title font-bold">{t('error:we_could_not_find_what_you_are_looking_for')}</p>
          <p className="mt-36">
            <Link
              className="cursor-pointer rounded-[4px] bg-seconderyRed p-[16px] text-title !text-white no-underline hover:opacity-80"
              to={`/${locale}`}
            >
              {t('system:take_me_to_the_homepage')}
            </Link>
          </p>
        </div>
      </div>
    </div>
  )
}
