import classnames from 'classnames'
import { format, parseISO } from 'date-fns'
import React from 'react'
import { Helmet } from 'react-helmet-async'
import { Link, useSearchParams } from 'react-router-dom'
import { useSet } from 'react-use'
import * as Api from 'src/api'
import { DeleteIcon } from 'src/assets/icons/customIcons/Delete'
import { EnvelopeIcon } from 'src/assets/icons/customIcons/Envelope'
import { EnvelopeOpenIcon } from 'src/assets/icons/customIcons/EnvelopeOpen'
import { NoPicture } from 'src/assets/icons/customIcons/noPicture'
import { useApi } from 'src/helpers/hooks'
import { useAuthenticatedHeaders } from 'src/hooks/auth/app'
import { useLocale } from 'src/hooks/locale/locale'
import { useDateLocale, useTranslatable } from 'src/hooks/locale/utils'
import { useActiveSemester } from 'src/hooks/semesters'
import EmptyMessages from 'src/imgs/empty-messages.svg'
import * as Table from 'src/tailwind/components/Table'
import { ErrorBoundary } from 'src/views/components/Error'
import ErrorElement from 'src/views/components/ErrorElement'
import Swal from 'sweetalert2'
import Loader from '../../../components/Loader'
import NoContent from '../../../components/NoContent'
import Pagination from '../../../components/Pagination'
import MessagesForm from './MessagesForm'

export default function MessageInboxPage(): JSX.Element | null {
  const t = useTranslatable()

  return (
    <>
      <Helmet title={t('message:messages_inbox')} />
      <div className="w-full flex-auto p-5 px-0">
        <MessagesForm />
        <React.Suspense fallback={<Loader className="m-auto flex" />}>
          <ErrorBoundary errorElement={<ErrorElement />}>
            <PageContent />
          </ErrorBoundary>
        </React.Suspense>
      </div>
    </>
  )
}

function PageContent(): JSX.Element | null {
  const t = useTranslatable()
  const locale = useLocale()
  const dateLocale = useDateLocale()
  const headers = useAuthenticatedHeaders()
  const [searchParams] = useSearchParams()
  const page = searchParams.get('page')
  const query = searchParams.get('query')
  const activeSemester = useActiveSemester()
  const semIdParam = searchParams.get('semId')
  const semId = semIdParam ?? activeSemester?.id
  const [selections, selectionActions] = useSet()
  const perPage = searchParams.get('perPage')
  const messages = useApi({
    endpoint: Api.getMessagesInbox,
    params: React.useMemo(
      () => ({
        headers,
        query: {
          filters: {
            search: query?.length === 0 ? null : query,
          },
          semId: semId!,
          perPage: Number(perPage),
          page: Number(page) ?? 1,
        },
      }),

      [headers, page, query, semId, perPage]
    ),
    shouldFetch: semId != null,
  })

  const SELECT = React.useCallback(
    async (messageId: string) => {
      selectionActions.add(messageId)
    },
    [selectionActions]
  )

  const UNSELECT = React.useCallback(
    async (messageId: string) => {
      selectionActions.remove(messageId)
    },
    [selectionActions]
  )

  const SELECT_ALL = React.useCallback(async () => {
    for (let i = 0; i < messages.data!.data.length; i++) {
      selectionActions.add(messages.data!.data[i]!.id)
    }
  }, [messages.data, selectionActions])

  const UNSELECT_ALL = React.useCallback(async () => {
    for (let i = 0; i < messages.data!.data.length; i++) {
      selectionActions.remove(messages.data!.data[i]!.id)
    }
  }, [messages.data, selectionActions])

  const DELETE_SELECTED = React.useCallback(async () => {
    await Api.deleteMessages({
      headers,
      body: {
        semId: semId!,
        messageIds: Array.from(selections).join(','),
      },
    })
    selectionActions.reset()
    void messages.mutate()
  }, [headers, semId, selections, selectionActions, messages])

  const MARK_SELECTED_AS_READ = React.useCallback(async () => {
    await Api.patchMessagesMarkRead({
      headers,
      body: {
        semId: semId!,
        messageIds: Array.from(selections).join(','),
      },
    })
    void messages.mutate()
  }, [headers, semId, selections, messages])

  const MARK_SELECTED_AS_UNREAD = React.useCallback(async () => {
    await Api.patchMessagesMarkUnread({
      headers,
      body: {
        semId: semId!,
        messageIds: Array.from(selections).join(','),
      },
    })
    void messages.mutate()
  }, [semId, headers, messages, selections])
  if (messages.isValidating || messages.data == null) return <Loader className="mx-auto my-10 flex" />
  const pagination = <Pagination pagination={messages.data.meta.pagination} />
  let isAllSelected = true

  if (selections.size === messages.data.data.length) {
    for (let i = 0; i < messages.data.data.length; i++) {
      if (!selections.has(messages.data.data[i]!.id)) {
        isAllSelected = false
        break
      }
    }
  } else {
    isAllSelected = false
  }

  return (
    <>
      <div className="flex justify-end">
        {selections.size > 0 ? (
          <>
            <div className="px-1">
              <button
                type="button"
                className="flex rounded-[18px] p-[10px] text-xs"
                onClick={() => void MARK_SELECTED_AS_READ()}
              >
                <span className="w-[18px]">
                  <EnvelopeOpenIcon />
                </span>
                <span className="pl-2 text-primaryTextColor">{t('common:mark_as_read')}</span>
              </button>
            </div>
            <div className="px-1">
              <button
                type="button"
                className="flex rounded-[18px] p-[10px] text-xs"
                onClick={() => void MARK_SELECTED_AS_UNREAD()}
              >
                <span className="w-[18px]">
                  <EnvelopeIcon />
                </span>
                <span className="pl-2 text-primaryTextColor">{t('common:mark_as_unread')}</span>
              </button>
            </div>
            <div className="px-1">
              <button
                type="button"
                className="flex rounded-[18px] p-[10px] text-xs"
                onClick={() => {
                  void (async () => {
                    const alert = await Swal.fire({
                      title: t('common:are_you_sure'),
                      icon: 'warning',
                      text: t('uncategorized:can_not_revert'),
                      showCancelButton: true,
                      confirmButtonColor: '#0D6EFD',
                      cancelButtonColor: '#6C757D',
                      confirmButtonText: t('common:confirm'),
                      cancelButtonText: t('common:cancel'),
                    })
                    if (alert.isConfirmed) {
                      void DELETE_SELECTED()
                    }
                  })()
                }}
              >
                <span className="w-[18px]">
                  <DeleteIcon />
                </span>
                <span className="pl-2 text-primaryTextColor">{t('common:delete')}</span>
              </button>
            </div>
          </>
        ) : null}
      </div>
      {messages.data.data.length === 0 && !messages.isValidating ? (
        <NoContent
          image={EmptyMessages}
          header={t('error:no_messages_found')}
          subHeader={t('error:no_recieved_messages_found')}
          marginTop="5"
        />
      ) : messages.isValidating ? (
        <Loader className="m-auto flex" style={{ margin: 'auto' }} />
      ) : (
        <>
          {pagination}
          <div className="mt-3 w-full rounded-xl bg-card py-[20px]">
            <Table.Table className="border-spacing-y-2">
              <Table.Thead>
                <Table.Tr>
                  <Table.Th>
                    <input
                      type="checkbox"
                      ref={(elem) => {
                        if (elem != null) {
                          elem.indeterminate = !isAllSelected && selections.size > 0
                        }
                      }}
                      checked={isAllSelected || selections.size > 0}
                      onChange={(e) => {
                        if (e.currentTarget.checked) {
                          void SELECT_ALL()
                        } else {
                          void UNSELECT_ALL()
                        }
                      }}
                    />
                  </Table.Th>
                  <Table.Th />
                  <Table.Th className="text-bodyText">{t('message:subject')}</Table.Th>
                  <Table.Th className="text-bodyText">{t('message:sender')}</Table.Th>
                  <Table.Th className="text-bodyText text-primaryTextColor">{t('common:date')}</Table.Th>
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>
                {messages.data.data.map((message) => (
                  <Table.Tr
                    key={message.id}
                    className={classnames('hover:bg-black/0', {
                      'font-bold': !message.seen,
                    })}
                  >
                    <Table.Td>
                      <input
                        type="checkbox"
                        className="border-gray-300"
                        checked={selections.has(message.id)}
                        onChange={(e) => {
                          if (e.currentTarget.checked) {
                            void SELECT(message.id)
                          } else {
                            void UNSELECT(message.id)
                          }
                        }}
                      />
                    </Table.Td>
                    <Table.Td>
                      {message.seen ? (
                        <div className="w-[18px]">
                          <EnvelopeOpenIcon />
                        </div>
                      ) : (
                        <div className="w-[18px]">
                          <EnvelopeIcon />
                        </div>
                      )}
                    </Table.Td>
                    <Table.Td className="max-w-[420px] text-bodyText">
                      <Link
                        to={`/${locale}/messages/${message.id}/${message.semId}`}
                        className="text-primaryBlueLink dark:text-primaryTextColor"
                      >
                        {message.subject}
                      </Link>
                    </Table.Td>
                    <Table.Td className="flex items-center text-bodyText">
                      {message.sender!.photoUrl != null && message.sender!.photoUrl.length > 0 ? (
                        <img
                          src={message.sender!.photoUrl}
                          style={{
                            borderRadius: '100%',
                            objectFit: 'contain',
                            background: 'white',
                            height: 24,
                            width: 24,
                            float: 'left',
                            display: 'inline-block',
                            marginRight: '4px',
                          }}
                        />
                      ) : (
                        <div className="w-[32px]">
                          <NoPicture />
                        </div>
                      )}
                      <Link to={`/${locale}/users/${message.sender!.uid}`} className="ml-2">
                        {message.sender!.fullName}
                      </Link>
                    </Table.Td>
                    <Table.Td className="text-bodyText">
                      {format(parseISO(message.sentAt), 'd MMMM yyyy, HH:mm', {
                        locale: dateLocale,
                      })}
                    </Table.Td>
                  </Table.Tr>
                ))}
              </Table.Tbody>
            </Table.Table>
          </div>
          {pagination}
        </>
      )}
      <div className="flex justify-end">
        {selections.size > 0 ? (
          <>
            <div className="px-1">
              <button
                type="button"
                className="flex rounded-[18px] p-[10px] text-xs"
                onClick={() => void MARK_SELECTED_AS_READ()}
              >
                <span className="w-[18px]">
                  <EnvelopeOpenIcon />
                </span>
                <span className="pl-2 text-primaryTextColor">{t('common:mark_as_read')}</span>
              </button>
            </div>
            <div className="px-1">
              <button
                type="button"
                className="flex rounded-[18px] p-[10px] text-xs"
                onClick={() => void MARK_SELECTED_AS_UNREAD()}
              >
                <span className="w-[18px]">
                  <EnvelopeIcon />
                </span>
                <span className="pl-2 text-primaryTextColor">{t('common:mark_as_unread')}</span>
              </button>
            </div>
            <div className="px-1">
              <button
                type="button"
                className="flex rounded-[18px] p-[10px] text-xs"
                onClick={() => {
                  void (async () => {
                    const alert = await Swal.fire({
                      title: t('common:are_you_sure'),
                      icon: 'warning',
                      text: t('uncategorized:can_not_revert'),
                      showCancelButton: true,
                      confirmButtonColor: '#0D6EFD',
                      cancelButtonColor: '#6C757D',
                      confirmButtonText: t('common:confirm'),
                      cancelButtonText: t('common:cancel'),
                    })
                    if (alert.isConfirmed) {
                      void DELETE_SELECTED()
                    }
                  })()
                }}
              >
                <span className="w-[18px]">
                  <DeleteIcon />
                </span>
                <span className="pl-2 text-primaryTextColor">{t('common:delete')}</span>
              </button>
            </div>
          </>
        ) : null}
      </div>
    </>
  )
}
