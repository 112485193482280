export function LogIcon(): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g id="log_icon" transform="translate(-305 -415)">
        <g
          id="Rectangle_3664"
          data-name="Rectangle 3664"
          transform="translate(305 415)"
          fill="#fff"
          stroke="#707070"
          strokeWidth="1"
          opacity="0"
        >
          <rect width="24" height="24" stroke="none" />
          <rect x="0.5" y="0.5" width="23" height="23" fill="none" />
        </g>
        <rect
          id="Rectangle_3694"
          data-name="Rectangle 3694"
          width="16"
          height="2"
          rx="1"
          transform="translate(305 433)"
          className="fill-svgColor"
        />
        <rect
          id="Rectangle_4328"
          data-name="Rectangle 4328"
          width="6"
          height="2"
          rx="1"
          transform="translate(323 433)"
          className="fill-svgColor"
        />
        <rect
          id="Rectangle_3695"
          data-name="Rectangle 3695"
          width="16"
          height="2"
          rx="1"
          transform="translate(305 426)"
          className="fill-svgColor"
        />
        <rect
          id="Rectangle_4329"
          data-name="Rectangle 4329"
          width="6"
          height="2"
          rx="1"
          transform="translate(323 426)"
          className="fill-svgColor"
        />
        <rect
          id="Rectangle_3696"
          data-name="Rectangle 3696"
          width="16"
          height="2"
          rx="1"
          transform="translate(305 419)"
          className="fill-svgColor"
        />
        <rect
          id="Rectangle_4330"
          data-name="Rectangle 4330"
          width="6"
          height="2"
          rx="1"
          transform="translate(323 419)"
          className="fill-svgColor"
        />
      </g>
    </svg>
  )
}
