import React from 'react'
import { Helmet } from 'react-helmet-async'
import { Link, useLocation, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import * as Api from 'src/api'
import { NoPicture } from 'src/assets/icons/customIcons/noPicture'
import { useApi } from 'src/helpers/hooks'
import { useAuthenticatedHeaders } from 'src/hooks/auth/app'
import { useAuth } from 'src/hooks/auth/auth'
import { useLocale } from 'src/hooks/locale/locale'
import { useTranslatable } from 'src/hooks/locale/utils'

import { Section } from 'src/tailwind/components/Section'
import BackButton from 'src/views/components/BackButton'
import { ErrorBoundary } from 'src/views/components/Error'
import ErrorElement from 'src/views/components/ErrorElement'
import Swal from 'sweetalert2'
import Loader from '../../components/Loader'

export default function UserPage(): JSX.Element | null {
  const t = useTranslatable()

  return (
    <>
      <Helmet title={t('person:user')} />
      <React.Suspense fallback={<Loader className="m-auto flex" />}>
        <ErrorBoundary errorElement={<ErrorElement />}>
          <PageContent />
        </ErrorBoundary>
      </React.Suspense>
    </>
  )
}

function PageContent(): JSX.Element | null {
  const { uid } = useParams()
  const t = useTranslatable()
  const locale = useLocale()
  const headers = useAuthenticatedHeaders()
  const auth = useAuth()
  const { pathname } = useLocation()

  const user = useApi({
    endpoint: Api.getUser,
    params: React.useMemo(
      () => ({
        headers,
        args: {
          uid: uid!,
        },
      }),

      [headers, uid]
    ),
  })

  const ASK_RECOMMENDATION = React.useCallback(
    async (id: string) => {
      try {
        await Api.postStudentRecommendationsRequest({
          headers,
          args: {
            user_id: id,
          },
        })

        window.scroll(0, 0)
        toast.success(t('recommendation:asking_for_a_letter_of_recommendation_has_been_sent_successfully'))
      } catch (error) {
        toast.error(t('error:an_error_occurred'))
      }
    },
    [headers, t]
  )

  const breadcrumbsItems = [{ page: user.data.fullName, path: pathname }]

  return (
    <Section title={t('common:detailed_information')} rightElement={<BackButton />} breadcrubms={breadcrumbsItems}>
      <div className="flex w-full flex-wrap items-start rounded-xl bg-card px-7 py-6 sm:!flex-nowrap">
        <div className="col-auto mb-3 mr-5 flex justify-center align-middle">
          {user.data.photoUrl != null ? (
            <img
              src={user.data.photoUrl}
              className="rounded"
              style={{
                height: 220,
                objectFit: 'contain',
                background: 'white',
              }}
            />
          ) : (
            <div className="w-24">
              <NoPicture />
            </div>
          )}
        </div>
        <div className="!mt-3 sm:!mt-0 sm:ml-10">
          <h3 className="mb-2 text-2xl font-bold">{user.data.fullName}</h3>
          {user.data.email != null && (
            <div className="mb-2">
              <a className="text-title hover:opacity-70" href={`mailto:${user.data.email}`}>
                {user.data.email}
              </a>
            </div>
          )}
          <div className="mb-6 text-caption">
            {user.data.profiles?.map((profile) => t(`degrees:${profile.degree}`)).join(', ')}
          </div>
          <div className="flex flex-wrap items-center">
            <Link
              to={`/${locale}/messages/compose/${user.data.uid}`}
              className="mb-2 mr-2 cursor-pointer rounded-[4px] bg-primaryBlue px-4 py-2.5 text-center text-title text-white hover:opacity-80 xxs:mr-0 xxs:w-full xs:mr-0 xs:w-full"
            >
              {t('message:send_message')}
            </Link>
            {user.data.profiles?.map((profile) => {
              if (profile.type === 'UserProfileLecturer' && auth.profile?.type === 'UserProfileStudent') {
                return (
                  <button
                    key={profile.id}
                    type="button"
                    className="mb-2 cursor-pointer rounded-[4px] bg-primaryBlue px-4 py-2.5 text-title text-white hover:opacity-80 xxs:w-full xs:w-full"
                    onClick={() => {
                      void (async () => {
                        const alert = await Swal.fire({
                          title: t('recommendation:do_you_really_want_to_request_recommendation'),
                          icon: 'question',
                          showCancelButton: true,
                          confirmButtonColor: '#0D6EFD',
                          cancelButtonColor: '#6C757D',
                          confirmButtonText: t('common:confirm'),
                          cancelButtonText: t('common:cancel'),
                        })
                        if (alert.isConfirmed) {
                          void ASK_RECOMMENDATION(user.data.id)
                        }
                      })()
                    }}
                  >
                    {t('recommendation:ask_for_a_recommendation')}
                  </button>
                )
              } else return null
            })}
          </div>
        </div>
      </div>
    </Section>
  )
}
