import classnames from 'classnames'
import { useField } from 'formik'
import { type SelectComponents } from 'node_modules/react-select/dist/declarations/src/components'
import * as React from 'react'
import { type GroupBase } from 'react-select'
import { useTranslatable } from 'src/hooks/locale/utils'
import Select from '../../Select'
import { useFormData } from './Form'

interface Option {
  readonly label: string
  readonly value: string
}

interface Props {
  readonly options: readonly Option[] | null
  readonly placeholder?: string
  readonly label?: string
  readonly name: string
  readonly required?: boolean
  readonly disabled?: boolean
  readonly isLoading?: boolean
  readonly isMulti?: boolean
  readonly labelAnimation?: boolean
  readonly components?: Partial<SelectComponents<any, boolean, GroupBase<any>>> | undefined
  readonly instructions?: React.ReactElement
}

export default function SelectInput({
  label,
  labelAnimation = false,
  components,
  instructions,
  required,
  ...props
}: Props): React.ReactElement {
  const t = useTranslatable()
  const [field, meta, helpers] = useField({
    ...props,
    validate(value) {
      if (required === true) {
        if (value == null || value === '' || value === undefined || ((props.isMulti ?? false) && value[0] == null)) {
          return label !== undefined ? `${label} ${t('common:is_required')}` : t('common:field_is_required')
        }
      }
    },
  })

  const { setValue, setTouched } = helpers
  const [formData] = useFormData()
  const errors = formData.errors.fields[props.name] ?? []
  const isInvalid = (errors.length > 0 || meta.error != null) && meta.touched ? '!border-primaryRed' : ''
  const [focused, setFocused] = React.useState(false)

  const handleChange = React.useCallback(
    (option: { value: any }) => {
      void setValue(props.isMulti ?? false ? option : option?.value)
    },
    [props.isMulti, setValue]
  )

  const getValue = (): any => {
    if (props.options != null) {
      return props.isMulti ?? false
        ? field.value
        : props.options.find((option) => {
            return option.value === field.value
          })
    } else {
      return props.isMulti ?? false ? [] : ('' as any)
    }
  }

  return (
    <div className="mb-3 w-full">
      <div
        className={classnames('relative w-full', {
          labelAnimation,
        })}
        data-testid={props.name}
      >
        {label != null && (
          <label
            className={`${
              labelAnimation
                ? `absolute left-[12px] z-10 flex-nowrap text-lightPrimaryIconText duration-200 ease-in-out ${
                    (field.value != null && field.value !== '') || focused
                      ? 'top-[5%] translate-y-[-10%] text-[11px]'
                      : 'top-1/2 -translate-y-1/2'
                  }`
                : 'mb-2 flex flex-nowrap items-center pl-0 font-bold text-primaryTextColor'
            }`}
            htmlFor={props.name}
          >
            {label}{' '}
            {required != null && (
              <span className="pl-1 text-lightSecondaryWarning" data-testid={`${label ?? props.placeholder}.required`}>
                *
              </span>
            )}{' '}
            {instructions}
          </label>
        )}
        <Select
          placeholder={
            props.placeholder != null && !labelAnimation
              ? props.placeholder
              : props.placeholder == null && !labelAnimation
                ? t('common:select')
                : ''
          }
          className={`p-0 ${isInvalid}`}
          options={props.options!}
          name={field.name}
          // defaultValue={props.options}
          value={getValue()}
          onChange={handleChange}
          onFocus={() => setFocused(true)}
          onBlur={() => {
            void setTouched(true, true)
            setFocused(false)
          }}
          instanceId={props.name}
          hasError={
            meta.error != null &&
            meta.touched &&
            (required === true ? field.value === '' || field.value === undefined : true)
          }
          inputId={props.name}
          isDisabled={props.disabled === true || props.isLoading}
          noOptionsMessage={() => t('common:no_options')}
          isLoading={props.isLoading}
          isMulti={props.isMulti}
          components={components}
        />
      </div>
      {meta.error != null &&
      meta.touched &&
      (required === true ? field.value === '' || field.value === undefined : true) ? (
        <div className="mt-2 text-[13px] text-errorMessages">{meta.error}</div>
      ) : null}
      {errors.map((error, i) => (
        <div key={i} className="mt-2 text-[13px] text-errorMessages">
          {error}
        </div>
      ))}
    </div>
  )
}
