export function DeleteIcon(): JSX.Element {
  return (
    <svg id="delete_icon" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 24 24">
      <rect id="Rectangle_3629" data-name="Rectangle 3629" width="24" height="24" opacity="0" />
      <rect
        id="Rectangle_3630"
        data-name="Rectangle 3630"
        width="22"
        height="22"
        transform="translate(1 1)"
        opacity="0"
      />
      <g
        id="Rectangle_4297"
        data-name="Rectangle 4297"
        transform="translate(4 4)"
        fill="none"
        stroke="gray"
        strokeWidth="1"
      >
        <path d="M0,0H16a0,0,0,0,1,0,0V17a2,2,0,0,1-2,2H2a2,2,0,0,1-2-2V0A0,0,0,0,1,0,0Z" stroke="none" />
        <path
          d="M1,.5H15a.5.5,0,0,1,.5.5V17A1.5,1.5,0,0,1,14,18.5H2A1.5,1.5,0,0,1,.5,17V1A.5.5,0,0,1,1,.5Z"
          fill="none"
        />
      </g>
      <g
        id="Rectangle_4298"
        data-name="Rectangle 4298"
        transform="translate(8 1)"
        fill="none"
        stroke="gray"
        strokeWidth="1"
      >
        <path d="M2,0H6A2,2,0,0,1,8,2V4A0,0,0,0,1,8,4H0A0,0,0,0,1,0,4V2A2,2,0,0,1,2,0Z" stroke="none" />
        <path d="M2,.5H6A1.5,1.5,0,0,1,7.5,2V3a.5.5,0,0,1-.5.5H1A.5.5,0,0,1,.5,3V2A1.5,1.5,0,0,1,2,.5Z" fill="none" />
      </g>
      <rect
        id="Rectangle_4299"
        data-name="Rectangle 4299"
        width="20"
        height="1"
        transform="translate(2 4)"
        fill="gray"
      />
      <rect
        id="Rectangle_4300"
        data-name="Rectangle 4300"
        width="1"
        height="9"
        transform="translate(10 9)"
        fill="gray"
      />
      <rect
        id="Rectangle_4301"
        data-name="Rectangle 4301"
        width="1"
        height="9"
        transform="translate(13 9)"
        fill="gray"
      />
    </svg>
  )
}
