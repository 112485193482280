import * as React from 'react'
import { Helmet } from 'react-helmet-async'
import { toast } from 'react-toastify'
import * as Api from 'src/api'
import { useApi } from 'src/helpers/hooks'
import { useAuthenticatedHeaders } from 'src/hooks/auth/app'
import { useTranslatable } from 'src/hooks/locale/utils'
import { requestNotificationsPermission, useNotificationsState } from 'src/state/notifications/Notifications'
import { Button } from 'src/tailwind/components/Button'
import { Card } from 'src/tailwind/components/Card'
import * as Table from 'src/tailwind/components/Table'
import instruction from 'src/videos/notifications-instruction.mp4'
import { Form } from 'src/views/components/forms/formik/Form'
import { FormSubmit } from 'src/views/components/forms/formik/FormSubmit'
import SwitchButton from 'src/views/components/forms/formik/SwitchButton'
import PopoverComponent from 'src/views/components/PopoverComponent'
import SuspenseWrapper from 'src/views/includes/SuspenseWrapper'

export default function ProfileNotificationSettingsPage(): JSX.Element | null {
  const t = useTranslatable()

  return (
    <>
      <Helmet title={t('notification:notification_settings')} />
      <SuspenseWrapper>
        <PageContent />
      </SuspenseWrapper>
    </>
  )
}

function PageContent(): JSX.Element | null {
  const headers = useAuthenticatedHeaders()
  const [successfullySubmited, setSuccessfullySubmited] = React.useState(false)
  const t = useTranslatable()
  const notificationPermission = useNotificationsState()

  const { data } = useApi({
    endpoint: Api.getUserSettingsNotifications,
    params: React.useMemo(
      () => ({
        headers,
      }),
      [headers]
    ),
  })

  const save = React.useCallback(
    async (values: Api.putUserSettingsNotificationsParams['body']): Promise<void> => {
      try {
        await Api.putUserSettingsNotifications({
          headers,
          body: values,
        })
        toast.success(t('common:updated_successfully'))
        setSuccessfullySubmited(true)
      } catch (error) {
        toast.error(t('error:an_error_occurred'))
      }
    },
    [headers, t]
  )

  const initialValues = React.useMemo(
    () => ({
      notifyOnLectureStarts: {
        mail: data.notifyOnLectureStartsByEmail,
        push: data.notifyOnLectureStartsByPush,
        sms: data.notifyOnLectureStartsBySms,
      },
      notifyOnMessage: {
        mail: data.notifyOnMessageByEmail,
        push: data.notifyOnMessageByPush,
        sms: data.notifyOnMessageBySms,
      },
      notifyOnScoreSave: {
        mail: data.notifyOnScoreSaveByEmail,
        push: data.notifyOnScoreSaveByPush,
        sms: data.notifyOnScoreSaveBySms,
      },
      notifyOnNewsAdd: {
        mail: data.notifyOnNewsAddByEmail,
        push: data.notifyOnNewsAddByPush,
        sms: data.notifyOnNewsAddBySms,
      },
      notifyOnMaterialAdd: {
        mail: data.notifyOnMaterialAddByEmail,
        push: data.notifyOnMaterialAddByPush,
        sms: data.notifyOnMaterialAddBySms,
      },
    }),
    [data]
  )

  return (
    <>
      <Card className="mb-5 flex w-full p-3">
        <div className="flex w-full flex-col">
          <h5 className="font-bold">{t('notification:notification_settings')}</h5>
          <div className="flex w-full items-center justify-between text-primaryTextColor">
            {notificationPermission === 'default' && (
              <>
                <p>{t('notification:in_order_to_get_notifications_on_desktop_you_need_to_allow_this_setting')}</p>
                <Button onClick={() => void requestNotificationsPermission()} variant="blue">
                  {t('notification:allow_browser_notifications')}
                </Button>
              </>
            )}
            {notificationPermission === 'denied' && (
              <div className="mt-2 flex flex-col justify-between lg:flex-row">
                <p className="my-4 mr-2 text-title">
                  {t(
                    'notification:you_need_to_allow_browser_to_get_new_notifications_please_follow_instructions_shown_on_video'
                  )}
                </p>
                <video className="mt-2" autoPlay loop muted src={instruction} />
              </div>
            )}
            {notificationPermission === 'granted' && (
              <p>{t('notification:you_are_all_set_to_get_new_notifications')}</p>
            )}
          </div>
        </div>
      </Card>
      <Form initialValues={initialValues} enableReinitialize onSubmit={save} isConfirmable={!successfullySubmited}>
        <Table.Table>
          <Table.Thead>
            <Table.Tr>
              <Table.Th />
              <Table.Th className="whitespace-nowrap !text-center">{t('person:email')}</Table.Th>
              <Table.Th className="flex items-center justify-center whitespace-nowrap text-center">
                {t('notification:desktop_push')}
                <PopoverComponent placement="right">
                  {t(
                    'notification:this_setting_applies_to_all_devices_in_your_account_if_you_want_to_chart_on_this_device_then_click_on_allow_browser_notifications'
                  )}
                </PopoverComponent>
              </Table.Th>
              <Table.Th className="whitespace-nowrap !text-center">{t('notification:sms')}</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {Object.keys(initialValues).map((item, index) => (
              <Row
                setting={(initialValues as { [key: string]: Api.NotificationsData })[item]!}
                keyName={item as keyof Api.putUserSettingsNotificationsParams['body']}
                key={index}
              />
            ))}
          </Table.Tbody>
        </Table.Table>
        <FormSubmit
          classNames={{
            root: 'flex justify-end mt-3',
          }}
        />
      </Form>
    </>
  )
}

function Row({
  setting,
  keyName,
}: {
  setting: Api.NotificationsData
  keyName: keyof Api.putUserSettingsNotificationsParams['body']
}): JSX.Element {
  const title = useRowTitle(keyName)

  return (
    <Table.Tr>
      <Table.Td>{title} </Table.Td>
      <>
        {Object.keys(setting).map((item, index) => (
          <Table.Td key={index} className="text-center">
            <SwitchButton name={`${keyName}.[${item}]`} />
          </Table.Td>
        ))}
      </>
    </Table.Tr>
  )
}

function useRowTitle(key: keyof Api.putUserSettingsNotificationsParams['body']): string {
  const t = useTranslatable()

  switch (key) {
    case 'notifyOnMessage':
      return t('notification:notify_about_new_message')
    case 'notifyOnLectureStarts':
      return t('notification:notify_about_lecture_start')
    case 'notifyOnScoreSave':
      return t('notification:notify_about_score_save')
    case 'notifyOnNewsAdd':
      return t('notification:notify_about_news_add')
    case 'notifyOnMaterialAdd':
      return t('notification:notify_about_material_add')
  }
}
