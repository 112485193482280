import * as React from 'react'
import { Helmet } from 'react-helmet-async'
import { useAuth } from 'src/hooks/auth/auth'
import { useTranslatable } from 'src/hooks/locale/utils'
import { ErrorBoundary } from 'src/views/components/Error'
import ErrorElement from 'src/views/components/ErrorElement'
import Loader from 'src/views/components/Loader'

export default function ProfilePersonalInformationPage(): JSX.Element | null {
  const t = useTranslatable()

  return (
    <>
      <Helmet title={t('person:personal_information_profile')} />
      <React.Suspense fallback={<Loader className="m-auto flex" />}>
        <ErrorBoundary errorElement={<ErrorElement />}>
          <PageContent />
        </ErrorBoundary>
      </React.Suspense>
    </>
  )
}

function PageContent(): React.ReactElement | null {
  const t = useTranslatable()
  const auth = useAuth()

  if (auth.state !== 'Authenticated') return null

  const fields = [
    {
      label: t('person:degree'),
      value: t(`degrees:${auth.profile.degree}`),
    },
    {
      label: t('person:firstname'),
      value: auth.user.firstName,
    },
    {
      label: t('person:lastname'),
      value: auth.user.lastName,
    },
    {
      label: t('person:personal_number'),
      value: auth.user.personalNumber,
    },
    {
      label: t('person:birth_date'),
      value: auth.user.birthDate,
    },
    {
      label: t('person:juridical_address'),
      value: auth.user.juridicalAddress,
    },
  ]

  return (
    <div className="mt-6 w-full rounded-xl bg-card py-9 pl-9 text-primaryTextColor sm:px-9">
      {fields.map((field, key) => (
        <div key={key} className="mb-2.5 grid grid-cols-6">
          <div className="col-span-2">
            <label className="font-bold">{field.label}:</label>
          </div>
          <div className="col-span-4 float-left">
            <div>{field.value}</div>
          </div>
        </div>
      ))}
    </div>
  )
}
