import Parameters from 'src/assets/icons/customIcons/page-icons/Parameters'
import { useLocale } from 'src/hooks/locale/locale'
import { useTranslatable } from 'src/hooks/locale/utils'
import SubPageLayout from 'src/views/includes/SubPageLayout'

export default function ProfileSettingsLayout(): JSX.Element | null {
  const t = useTranslatable()
  const locale = useLocale()

  const subBreadcrumbsItems = [{ page: `${t('system:settings')}`, path: `/${locale}/profile/settings/general` }]

  return (
    <SubPageLayout
      Icon={Parameters}
      title={t('system:settings')}
      subBreadcrubms={subBreadcrumbsItems}
      pages={[
        {
          title: t('profileSettingsLayout:general'),
          link: 'general',
        },
        {
          title: t('profileSettingsLayout:notifications'),
          link: 'notifications',
        },
      ]}
    />
  )
}
