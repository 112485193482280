import * as React from 'react'
import DocIcon from 'src/assets/icons/customIcons/Doc'
import ExcelIcon from 'src/assets/icons/customIcons/ExcelIcon'
import File from 'src/assets/icons/customIcons/File'
import JsonIcon from 'src/assets/icons/customIcons/JsonIcon'
import Mp3Icon from 'src/assets/icons/customIcons/Mp3Icon'
import { PdfIcon } from 'src/assets/icons/customIcons/Pdf'
import PhpIcon from 'src/assets/icons/customIcons/PhpIcon'
import PptIcon from 'src/assets/icons/customIcons/Ppt'
import RarIcon from 'src/assets/icons/customIcons/RarIcon'
import TextFileIcon from 'src/assets/icons/customIcons/TextFileIcon'
import VideoIcon from 'src/assets/icons/customIcons/Video'
import YamlIcon from 'src/assets/icons/customIcons/YamlIcon'
import ZipIcon from 'src/assets/icons/customIcons/ZipIcon'

interface Props {
  readonly type?: string | null | undefined
}

export default function FileIcon({ type }: Props): React.ReactElement {
  switch (type) {
    case 'jpeg':
    case 'jpg':
    case 'png':
    case 'bmp':
    case 'gif':
    case 'svg':
      return <File />
    case 'pdf':
      return <PdfIcon width="24" height="24" />
    case 'ppt':
    case 'pptx':
      return <PptIcon width="24" height="24" />
    case 'docx':
    case 'doc':
    case 'odt':
      return <DocIcon width="24" height="24" />
    case 'xls':
    case 'xlsx':
      return <ExcelIcon />
    case 'zip':
      return <ZipIcon />
    case 'rar':
    case 'tgz':
      return <RarIcon />
    case 'php':
      return <PhpIcon />
    case '3gp':
    case 'flv':
    case 'mpeg':
    case 'mp4':
    case 'avi':
    case 'mkv':
    case 'vmw':
      return <VideoIcon width="24" height="24" />
    case 'aac':
    case 'amr':
    case 'flac':
    case 'm4a':
    case 'ogg':
    case 'mp3':
    case 'wav':
      return <Mp3Icon />
    case 'json':
      return <JsonIcon />
    case 'yaml':
      return <YamlIcon />
    case 'txt':
      return <TextFileIcon />

    default:
      return <File />
  }
}
export function getFileIconTestId(type: string): string {
  switch (type) {
    case 'jpeg':
    case 'jpg':
    case 'png':
    case 'bmp':
    case 'gif':
    case 'svg':
      return 'fileIcon'
    case 'pdf':
      return 'pdfIcon'
    case 'ppt':
    case 'pptx':
      return 'pptIcon'
    case 'docx':
    case 'doc':
    case 'odt':
      return 'docIcon'
    case 'xls':
    case 'xlsx':
      return 'excelIcon'
    case 'zip':
      return 'zipIcon'
    case 'rar':
    case 'tgz':
      return 'rarIcon'
    case 'php':
      return 'phpIcon'
    case '3gp':
    case 'flv':
    case 'mpeg':
    case 'mp4':
    case 'avi':
    case 'mkv':
    case 'vmw':
      return 'videoIcon'
    case 'aac':
    case 'amr':
    case 'flac':
    case 'm4a':
    case 'ogg':
    case 'mp3':
    case 'wav':
      return 'mp3Icon'
    case 'json':
      return 'jsonIcon'
    case 'yaml':
      return 'yamlIcon'
    case 'txt':
      return 'textFileIcon'

    default:
      return 'fileIcon'
  }
}
