import classnames from 'classnames'
import { useField } from 'formik'
import * as React from 'react'
import { useTranslatable } from 'src/hooks/locale/utils'
import { useFormData } from './Form'

interface Props {
  readonly className?: string
  readonly placeholder?: string
  readonly name: string
  readonly required?: boolean
  readonly disabled?: boolean
  readonly label?: string
  readonly rows?: number
  readonly cols?: number
  readonly labelAnimation?: boolean
  readonly instructions?: React.ReactElement
}

export default function TextareaInput({
  required,
  labelAnimation = false,
  instructions,
  ...props
}: Props): React.ReactElement {
  const t = useTranslatable()
  const [field, meta] = useField({
    name: props.name,
    validate(value: string) {
      if (required === true) {
        if (value === '') {
          return props.label !== undefined ? `${props.label} ${t('common:is_required')}` : t('common:field_is_required')
        }
      }
    },
  })
  const [formData] = useFormData()
  const errors = formData.errors.fields[props.name] ?? []
  const isInvalid = (errors.length > 0 || meta.error != null) && meta.touched ? '!border-primaryRed' : ''
  const id = `label-${React.useId()}`

  return (
    <div className="relative flex w-full flex-col overflow-x-hidden">
      <div className="w-full">
        <div
          className={`flex flex-col${
            labelAnimation
              ? `relative overflow-hidden rounded-[4px] border border-borderColor bg-white dark:bg-[#323232] ${isInvalid}`
              : ''
          }`}
        >
          <label
            className={
              labelAnimation
                ? `absolute mt-px w-[99%] pl-[11px] text-lightPrimaryIconText duration-200 peer-focus:left-3 peer-focus:text-tiny dark:bg-[#323232] ${
                    field.value?.length > 0 ? 'text-[11px]' : ''
                  } `
                : 'mb-2 flex flex-nowrap items-center pr-6 font-bold text-primaryTextColor'
            }
            htmlFor={id}
          >
            {props.label}{' '}
            {required != null && (
              <span className="pl-1 text-primaryRed" data-testid={`${props.label}.required`}>
                *
              </span>
            )}{' '}
            {instructions}
          </label>
          <textarea
            {...field}
            {...props}
            id={id}
            data-testid={props.name}
            className={classnames(
              ` ${props.className ?? ''} ${
                labelAnimation
                  ? 'peer mt-[18px] w-full border-none pt-0'
                  : `rounded-[4px] border border-[#D9D9D9] ${isInvalid}`
              } `
            )}
          />
        </div>

        {meta.touched && meta.error != null ? (
          <div className="mt-2 text-[13px] text-errorMessages">{meta.error}</div>
        ) : null}

        {errors.map((error, i) => (
          <div key={i} className="mt-2 text-[13px] text-errorMessages">
            {error}
          </div>
        ))}
      </div>
    </div>
  )
}
