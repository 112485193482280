import ProfileIcon from 'src/assets/icons/customIcons/page-icons/Profile'
import { useLocale } from 'src/hooks/locale/locale'
import { useTranslatable } from 'src/hooks/locale/utils'
import SubPageLayout from 'src/views/includes/SubPageLayout'

export default function ProfileLayout(): JSX.Element | null {
  const t = useTranslatable()

  const locale = useLocale()

  const subBreadcrumbsItems = [{ page: `${t('person:profile')}`, path: `/${locale}/profile/personal` }]

  return (
    <SubPageLayout
      Icon={ProfileIcon}
      title={t('person:profile')}
      subBreadcrubms={subBreadcrumbsItems}
      pages={[
        {
          title: t('person:personal_information'),
          link: 'personal',
        },
        {
          title: t('person:contact_information_profile'),
          link: 'contact',
        },
      ]}
    />
  )
}
