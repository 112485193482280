interface Props {
  readonly width?: string
  readonly height?: string
}
export default function PptIcon({ width = 'auto', height = 'auto' }: Props): JSX.Element {
  return (
    <svg
      id="doc_icon"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 16"
      data-testid="pptIcon"
    >
      <rect id="Rectangle_4250" data-name="Rectangle 4250" width="16" height="16" opacity="0" />
      <g id="Path_25073" data-name="Path 25073" transform="translate(2)" fill="none">
        <path
          d="M 11.5 15.5 L 0.5 15.5 L 0.5 0.5 L 7.792900085449219 0.5 L 11.5 4.207099914550781 L 11.5 15.5 Z"
          stroke="none"
        />
        <path
          d="M 1 1 L 1 15 L 11 15 L 11 4.414219856262207 L 7.585780143737793 1 L 1 1 M 0 0 L 8 0 L 12 4 L 12 16 L 0 16 L 0 0 Z"
          stroke="none"
          fill="gray"
        />
      </g>
      <g id="Path_25072" data-name="Path 25072" transform="translate(9)" fill="none">
        <path d="M0,0H1L5,4V5H0Z" stroke="none" />
        <path
          d="M 1 1.414220094680786 L 1 4 L 3.585780143737793 4 L 1 1.414220094680786 M 0 0 L 1 0 L 5 4 L 5 5 L 0 5 L 0 0 Z"
          stroke="none"
          fill="gray"
        />
      </g>
      <text transform="translate(3 9)" fontSize="14" fontFamily="FiraGO-Regular, FiraGO">
        <tspan x="0" y="0" />
      </text>
      <g
        id="Rectangle_4275"
        data-name="Rectangle 4275"
        transform="translate(0 6)"
        fill="none"
        stroke="gray"
        strokeWidth="1"
      >
        <rect width="16" height="8" stroke="none" />
        <rect x="0.5" y="0.5" width="15" height="7" fill="none" />
      </g>
      <path
        id="Path_25078"
        data-name="Path 25078"
        d="M-4.233,0V-1.356h.972A1.281,1.281,0,0,0-1.869-2.67,1.292,1.292,0,0,0-3.261-4h-2V0Zm1.314-2.676a.442.442,0,0,1-.48.444h-.834v-.9H-3.4A.448.448,0,0,1-2.919-2.676ZM-.375,0V-1.356H.6A1.281,1.281,0,0,0,1.989-2.67,1.292,1.292,0,0,0,.6-4h-2V0ZM.939-2.676a.442.442,0,0,1-.48.444H-.375v-.9H.459A.448.448,0,0,1,.939-2.676ZM4.377,0V-3.1H5.493V-4H2.223v.9H3.345V0Z"
        transform="translate(8 12)"
        fill="gray"
      />
    </svg>
  )
}
