export default function RarIcon(): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" data-testid="rarIcon">
      <g id="doc_icon" transform="translate(1 7)">
        <rect
          id="Rectangle_4360"
          data-name="Rectangle 4360"
          width="24"
          height="24"
          transform="translate(-1 -7)"
          opacity="0"
        />
        <rect
          id="Rectangle_4250"
          data-name="Rectangle 4250"
          width="22"
          height="22"
          transform="translate(0 -6)"
          opacity="0"
        />
        <g
          id="Rectangle_4381"
          data-name="Rectangle 4381"
          transform="translate(0 8)"
          fill="#f2f2f2"
          stroke="gray"
          strokeWidth="1"
        >
          <rect width="22" height="8" rx="2" stroke="none" />
          <rect x="0.5" y="0.5" width="21" height="7" rx="1.5" fill="none" />
        </g>
        <g
          id="Rectangle_4385"
          data-name="Rectangle 4385"
          transform="translate(0 1)"
          fill="#f2f2f2"
          stroke="gray"
          strokeWidth="1"
        >
          <rect width="22" height="8" rx="2" stroke="none" />
          <rect x="0.5" y="0.5" width="21" height="7" rx="1.5" fill="none" />
        </g>
        <g
          id="Rectangle_4386"
          data-name="Rectangle 4386"
          transform="translate(0 -6)"
          fill="#f2f2f2"
          stroke="gray"
          strokeWidth="1"
        >
          <rect width="22" height="8" rx="2" stroke="none" />
          <rect x="0.5" y="0.5" width="21" height="7" rx="1.5" fill="none" />
        </g>
        <g
          id="Rectangle_4382"
          data-name="Rectangle 4382"
          transform="translate(8 -6)"
          fill="#f2f2f2"
          stroke="gray"
          strokeWidth="1"
        >
          <rect width="6" height="22" stroke="none" />
          <rect x="0.5" y="0.5" width="5" height="21" fill="none" />
        </g>
        <g
          id="Rectangle_4383"
          data-name="Rectangle 4383"
          transform="translate(7 5)"
          fill="#f2f2f2"
          stroke="gray"
          strokeWidth="1"
        >
          <rect width="8" height="7" rx="1" stroke="none" />
          <rect x="0.5" y="0.5" width="7" height="6" rx="0.5" fill="none" />
        </g>
        <rect
          id="Rectangle_4384"
          data-name="Rectangle 4384"
          width="1"
          height="3.5"
          transform="translate(10.5 5)"
          fill="gray"
        />
      </g>
    </svg>
  )
}
