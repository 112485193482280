import * as React from 'react'
import { Helmet } from 'react-helmet-async'
import { components, type OptionProps, type SingleValueProps } from 'react-select'
import { toast } from 'react-toastify'
import * as Api from 'src/api'
import { type LanguageString } from 'src/api'
import { useApi } from 'src/helpers/hooks'
import { useAuthenticatedHeaders } from 'src/hooks/auth/app'
import { useTranslatable } from 'src/hooks/locale/utils'
import { Card } from 'src/tailwind/components/Card'
import { FlagIcon } from 'src/views/components/FlagIcon'
import { Form } from 'src/views/components/forms/formik/Form'
import { FormSubmit } from 'src/views/components/forms/formik/FormSubmit'
import SelectInput from 'src/views/components/forms/formik/SelectInput'
import SwitchButton from 'src/views/components/forms/formik/SwitchButton'
import PopoverComponent from 'src/views/components/PopoverComponent'
import SuspenseWrapper from 'src/views/includes/SuspenseWrapper'

export default function ProfileGeneralSettingsPage(): JSX.Element | null {
  const t = useTranslatable()

  return (
    <>
      <Helmet title={t('system:general_settings')} />
      <SuspenseWrapper>
        <PageContent />
      </SuspenseWrapper>
    </>
  )
}

function PageContent(): JSX.Element | null {
  const headers = useAuthenticatedHeaders()
  const [successfullySubmited, setSuccessfullySubmited] = React.useState(false)
  const t = useTranslatable()

  const { data } = useApi({
    endpoint: Api.getUserSettingsGeneral,
    params: React.useMemo(
      () => ({
        headers,
      }),
      [headers]
    ),
  })

  const save = React.useCallback(
    async (values: Api.GeneralSettings): Promise<void> => {
      try {
        await Api.putUserSettingsGeneral({
          headers,
          body: values,
        })
        toast.success(t('common:updated_successfully'))
        setSuccessfullySubmited(true)
      } catch (error) {
        toast.error(t('error:an_error_occurred'))
      }
    },
    [headers, t]
  )

  const options: readonly OptionType[] = [
    { value: 'ka', label: t('common:georgian'), icon: 'ge' },
    { value: 'en', label: t('common:english'), icon: 'gb' },
  ]

  return (
    <Card className="mt-4 flex flex-col p-5 px-3 sm:!px-10">
      <Form initialValues={data} enableReinitialize onSubmit={save} isConfirmable={!successfullySubmited}>
        <div className="mb-3 flex flex-col items-start justify-start sm:!flex-row sm:items-center">
          <label htmlFor="language" className="flex w-auto justify-end text-primaryTextColor sm:!w-[210px]">
            {t('system:preferred_language')}
          </label>
          <div className="ml-0 mt-2 w-3/4 max-w-[270px] sm:ml-4 sm:mt-0 xxs:w-full xxs:max-w-full xs:w-full xs:max-w-full">
            <SelectInput
              placeholder={t('common:choose')}
              name="locale"
              options={options}
              components={{ Option, SingleValue, IndicatorSeparator: () => null }}
              required
            />
          </div>
        </div>
        <div className="mb-3 flex flex-col items-start justify-start sm:!flex-row sm:items-center">
          <label
            htmlFor="showMail"
            className="mb-2 flex w-auto items-center justify-end text-primaryTextColor sm:!w-[210px]"
          >
            {t('system:show_e_mail')}
            <PopoverComponent placement="right">
              <span className="text-primaryTextColor">{t('system:show_e_mail_on_personal_page')}</span>
            </PopoverComponent>
          </label>
          <div className="ml-0 mt-2 w-3/4 max-w-[270px] sm:ml-4 sm:mt-0">
            <SwitchButton name="showMail" />
          </div>
        </div>
        <FormSubmit
          classNames={{
            root: 'flex justify-end',
          }}
        />
      </Form>
    </Card>
  )
}

interface OptionType {
  readonly value: LanguageString
  readonly label: string
  readonly icon: string
}

// React select custom components
const SingleValue = (props: SingleValueProps<OptionType, boolean>): JSX.Element => (
  <components.SingleValue {...props} className="pl-1">
    <FlagIcon flag={props.data.icon} className="mr-2" />
    {props.data.label}
  </components.SingleValue>
)

const Option = (props: OptionProps<OptionType, boolean>): JSX.Element => {
  return (
    <components.Option {...props}>
      <FlagIcon flag={props.data.icon} className="mr-2" />
      {props.data.label}
    </components.Option>
  )
}
