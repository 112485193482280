import { EmailIcon } from 'src/assets/icons/customIcons/Email'
import { useLocale } from 'src/hooks/locale/locale'
import { useTranslatable } from 'src/hooks/locale/utils'
import SubPageLayout from 'src/views/includes/SubPageLayout'

export default function MessagesLayout(): JSX.Element | null {
  const t = useTranslatable()

  const locale = useLocale()

  const breadcrumbsItems = [{ page: `${t('message:messages')}`, path: `/${locale}/messages/inbox` }]

  return (
    <SubPageLayout
      Icon={EmailIcon}
      title={t('message:messages')}
      subBreadcrubms={breadcrumbsItems}
      pages={[
        {
          title: t('messagesLayout:inbox'),
          link: 'inbox',
        },
        {
          title: t('messagesLayout:outbox'),
          link: 'outbox',
        },
      ]}
    />
  )
}
