export function ClockIcon(): JSX.Element {
  return (
    <svg
      id="clock_icon"
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      data-testid="clockIcon"
    >
      <g id="Ellipse_6" data-name="Ellipse 6" fill="none" stroke="gray" strokeWidth="1">
        <circle cx="6" cy="6" r="6" stroke="none" />
        <circle cx="6" cy="6" r="5.5" fill="none" />
      </g>
      <path
        id="Path_7"
        data-name="Path 7"
        d="M53.024,25.989V28.95l2.618,1.2"
        transform="translate(-47 -23)"
        fill="none"
        stroke="gray"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
    </svg>
  )
}
