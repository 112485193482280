export function EnvelopeOpenIcon(): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 18 18">
      <g id="mail_opened_icon" transform="translate(-40 -16)">
        <rect
          id="Rectangle_5"
          data-name="Rectangle 5"
          width="18"
          height="18"
          transform="translate(40 16)"
          fill="#d6d6d6"
          opacity="0"
        />
        <rect
          id="Rectangle_10"
          data-name="Rectangle 10"
          width="16"
          height="16"
          transform="translate(41 17)"
          fill="#d6d6d6"
          opacity="0"
        />
        <g id="Path_25087" data-name="Path 25087" transform="translate(41 20)" fill="none">
          <path
            d="M2-.513C3.225-1.289,8-4,8-4s4.555,2.643,6,3.487S16,.9,16,2v9a2,2,0,0,1-2,2H2a2,2,0,0,1-2-2V2C0,.9.775.263,2-.513Z"
            stroke="none"
          />
          <path
            d="M 7.995140075683594 -2.846638679504395 C 6.741872787475586 -2.132476806640625 3.493812561035156 -0.2755136489868164 2.53516960144043 0.3318099975585938 C 1.344340324401855 1.086229801177979 1 1.460400104522705 1 2 L 1 11 C 1 11.55140018463135 1.448599815368652 12 2 12 L 14 12 C 14.55140018463135 12 15 11.55140018463135 15 11 L 15 2 C 15 1.493680000305176 14.93305015563965 1.190360069274902 13.49553966522217 0.350489616394043 C 12.35130786895752 -0.318023681640625 9.217292785644531 -2.137349128723145 7.995140075683594 -2.846638679504395 M 8 -4 C 8 -4 12.55468940734863 -1.357359886169434 14 -0.5129404067993164 C 15.44530963897705 0.3314800262451172 16 0.8954296112060547 16 2 L 16 11 C 16 12.10457038879395 15.10457038879395 13 14 13 L 2 13 C 0.8954296112060547 13 0 12.10457038879395 0 11 L 0 2 C 0 0.8954296112060547 0.7753896713256836 0.2628803253173828 2 -0.5129404067993164 C 3.224610328674316 -1.288760185241699 8 -4 8 -4 Z"
            stroke="none"
            fill="gray"
          />
        </g>
        <path
          id="Path_25086"
          data-name="Path 25086"
          d="M56.7,22.76l-7.623,4.884L41.369,22.76"
          transform="translate(-0.037 -0.179)"
          fill="none"
          stroke="gray"
          strokeWidth="1"
        />
      </g>
    </svg>
  )
}
