export default function Notifications(): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g id="notification_icon" transform="translate(-305 -415)">
        <g
          id="Rectangle_3664"
          data-name="Rectangle 3664"
          transform="translate(305 415)"
          className="fill-primaryWhite"
          stroke="#707070"
          strokeWidth="1"
          opacity="0"
        >
          <rect width="24" height="24" stroke="none" />
          <rect x="0.5" y="0.5" width="23" height="23" fill="none" />
        </g>
        <rect
          id="Rectangle_4211"
          className="fill-primaryTextColor"
          data-name="Rectangle 4211"
          width="22"
          height="2"
          transform="translate(306 434)"
        />
        <path
          id="Path_11"
          data-name="Path 11"
          d="M-255.333,5.667A1.335,1.335,0,0,0-254,4.333h-2.667a1.336,1.336,0,0,0,1.334,1.334m0,1.333A2.671,2.671,0,0,1-258,4.333,2.665,2.665,0,0,1-257.643,3h4.62a2.664,2.664,0,0,1,.357,1.333A2.67,2.67,0,0,1-255.333,7Z"
          transform="translate(572.333 432)"
          fill="gray"
        />
        <g
          id="Rectangle_4213"
          data-name="Rectangle 4213"
          transform="translate(309 418)"
          className="fill-primaryWhite stroke-svgColor"
          strokeWidth="2"
        >
          <path d="M8,0H8a8,8,0,0,1,8,8V18a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V8A8,8,0,0,1,8,0Z" stroke="none" />
          <path d="M8,1H8a7,7,0,0,1,7,7v8a1,1,0,0,1-1,1H2a1,1,0,0,1-1-1V8A7,7,0,0,1,8,1Z" fill="none" />
        </g>
        <rect
          id="Rectangle_4214"
          data-name="Rectangle 4214"
          width="2"
          height="5"
          rx="1"
          transform="translate(316 415)"
          className="fill-primaryTextColor"
        />
        <g
          id="Rectangle_4215"
          data-name="Rectangle 4215"
          transform="translate(314 434)"
          className="fill-primaryWhite stroke-svgColor"
          strokeWidth="2"
        >
          <path d="M0,0H6A0,0,0,0,1,6,0V2A3,3,0,0,1,3,5H3A3,3,0,0,1,0,2V0A0,0,0,0,1,0,0Z" stroke="none" />
          <path d="M2,1H4A1,1,0,0,1,5,2V2A2,2,0,0,1,3,4H3A2,2,0,0,1,1,2V2A1,1,0,0,1,2,1Z" fill="none" />
        </g>
      </g>
    </svg>
  )
}
