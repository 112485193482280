import { format, parseISO } from 'date-fns'
import * as React from 'react'
import { useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { Link, useLocation, useParams } from 'react-router-dom'
import * as Api from 'src/api'
import { ClockIcon } from 'src/assets/icons/customIcons/Clock'
import { EmailIcon } from 'src/assets/icons/customIcons/Email'
import InfoIcon from 'src/assets/icons/customIcons/page-icons/info'
import { useApi } from 'src/helpers/hooks'
import { useAuthenticatedHeaders } from 'src/hooks/auth/app'
import { useAuth } from 'src/hooks/auth/auth'
import { useLocale } from 'src/hooks/locale/locale'
import { useDateLocale, useTranslatable } from 'src/hooks/locale/utils'
import { useUserStateMutateOnMount } from 'src/hooks/userState'
import { Section } from 'src/tailwind/components/Section'
import BackButton from 'src/views/components/BackButton'
import { ErrorBoundary } from 'src/views/components/Error'
import ErrorElement from 'src/views/components/ErrorElement'
import Loader from '../../../components/Loader'
import UserPhoto from '../../../components/UserPhoto'
import MessageInfoPopup from './MessageInfoPopup'

export default function MessagePage(): JSX.Element | null {
  const t = useTranslatable()

  return (
    <>
      <Helmet title={t('message:message')} />
      <ErrorBoundary errorElement={<ErrorElement />}>
        <React.Suspense fallback={<Loader className="m-auto flex" />}>
          <PageContent />
        </React.Suspense>
      </ErrorBoundary>
    </>
  )
}

function PageContent(): JSX.Element | null {
  const { messageId, semesterId } = useParams()

  const t = useTranslatable()
  const locale = useLocale()
  const auth = useAuth()
  const dateLocale = useDateLocale()
  const headers = useAuthenticatedHeaders()
  const { pathname } = useLocation()
  const [open, setOpen] = useState(false)
  const message = useApi({
    endpoint: Api.getMessage,
    params: React.useMemo(
      () => ({
        headers,
        query: {
          semId: semesterId!,
        },
        args: {
          messageId: messageId!,
          semId: semesterId!,
        },
      }),
      [headers, messageId, semesterId]
    ),
  })

  useUserStateMutateOnMount()

  React.useEffect(() => {
    if (!message.data.seen) {
      void Api.patchMessagesMarkRead({
        headers,
        body: {
          messageIds: messageId!,
          semId: semesterId!,
        },
      })
    }
  }, [headers, message.data.seen, messageId, semesterId])

  if (auth.state !== 'Authenticated') return null

  const isInbox = message.data.receiver?.id === auth.user.id
  const otherUser = isInbox ? message.data.sender! : message.data.receiver!

  const title = isInbox ? t('message:messages_inbox') : t('message:messages_outbox')

  const breadcrumbsItems = [
    { page: `${t('message:messages')}`, path: isInbox ? `/${locale}/messages/inbox` : `/${locale}/messages/outbox` },
    { page: message.data.subject.substring(0, 100) + '...', path: pathname },
  ]

  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  const recommendationUrl = `/${locale}/lecturer/recommendation/${message.data?.senderProfileId}`
  const updatedMessageBody = message.data?.body.replace('{{RECOMMENDATION_URL}}', recommendationUrl)
  const showInfoPopup =
    auth.profile.type === 'UserProfileLecturer' && otherUser.profileForMessageView?.type === 'UserProfileStudent'

  return (
    <Section
      title={title}
      icon={<EmailIcon />}
      rightElement={<BackButton link={`/${locale}/messages/inbox`} />}
      breadcrubms={breadcrumbsItems}
    >
      <div>
        <div className="pointer mb-6 flex items-center text-primaryTextColor xxs:mb-2 xs:mb-2">
          <UserPhoto user={otherUser} />
          <Link to={`/${locale}/users/${otherUser.uid}`} className="mx-2 text-bodyText">
            {otherUser.fullName}
          </Link>
          {showInfoPopup && (
            <div className="cursor-pointer" onClick={() => setOpen(true)}>
              <InfoIcon />
            </div>
          )}
        </div>
        {showInfoPopup && (
          <MessageInfoPopup
            userName={otherUser.fullName}
            open={open}
            setOpen={setOpen}
            semester={otherUser.profileForMessageView?.semester}
          />
        )}
        <h5 className="mb-3 text-bodyText font-bold xxs:mb-1 xs:mb-1">{message.data.subject}</h5>
        <div className="mb-6 flex items-center text-xs text-lightPrimaryIconText">
          <div className="mr-2">
            <ClockIcon />
          </div>
          {format(parseISO(message.data.sentAt), 'd MMMM yyyy, HH:mm', { locale: dateLocale })}
        </div>

        <div
          className="recommendation_link income-message-content mb-4"
          dangerouslySetInnerHTML={{ __html: updatedMessageBody }}
        />
        <div className="flex w-full justify-between border-t border-borderColor">
          <div className="ml-auto mt-4">
            {isInbox && !(message.data.messageType === 'RECOMMENDATION_TYPE') ? (
              <>
                {' '}
                <Link
                  className="cursor-pointer rounded-[4px] bg-primaryBlue px-4 py-2.5 text-white hover:opacity-80"
                  to={`/${locale}/messages/reply/${message.data.id}/${message.data.semId}`}
                >
                  {t('message:reply')}
                </Link>
              </>
            ) : null}
          </div>
        </div>
      </div>
    </Section>
  )
}
