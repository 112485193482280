import { useEffect } from 'react'
import { Navigate, useSearchParams } from 'react-router-dom'
import { authObservableValue, useAuth, type AuthenticatedAuthState } from 'src/hooks/auth/auth'
import { useLocale } from 'src/hooks/locale/locale'

export default function NotificationsRedirectPage(): JSX.Element | null {
  const locale = useLocale()
  const [searchParams] = useSearchParams()
  const auth = useAuth() as AuthenticatedAuthState
  const type = searchParams.get('type')!
  const profileType = searchParams.get('profileType')!
  const profileId = searchParams.get('profileId')!
  const courseId = searchParams.get('courseId')!
  const messageId = searchParams.get('messageId')!
  const newsId = searchParams.get('newsId')!
  const semId = searchParams.get('semId')!
  const profile =
    auth.user.profiles.find(
      (p) =>
        p.id === profileId &&
        ((p.type === 'UserProfileAdministrator' && profileType === 'administrator') ||
          (p.type === 'UserProfileLecturer' && profileType === 'lecturer') ||
          (p.type === 'UserProfileStudent' && profileType === 'student'))
    ) ?? auth.profile

  useEffect(() => {
    // if the profile is already set, don't update it
    if (auth.profile.id === profile.id) return

    authObservableValue.setValue({
      state: 'Authenticated',
      user: auth.user,
      profile,
    })
  }, [auth, profile])

  // wait for auth to update
  if (auth.profile.id !== profile.id) return null

  if (type === 'ScoreSaveNotification') {
    return <Navigate to={`/${locale}/${profileType}/courses/${courseId}/scores`} replace />
  }
  if (type === 'MaterialAddNotification') {
    return <Navigate to={`/${locale}/${profileType}/courses/${courseId}/materials`} replace />
  }
  if (type === 'LectureStartsNotification') {
    return <Navigate to={`/${locale}/${profileType}/schedule`} replace />
  }
  if (type === 'NewsAddNotification') {
    return <Navigate to={`/${locale}/news/${newsId}`} replace />
  }
  if (type === 'NewMessageNotification') {
    return <Navigate to={`/${locale}/messages/${messageId}/${semId}`} replace />
  }

  return <Navigate to={`${locale}`} replace />
}
