import { useFormikContext } from 'formik'
import * as React from 'react'
import { useTranslatable } from 'src/hooks/locale/utils'

interface Props {
  readonly label?: string
  readonly children?: React.ReactNode
  readonly classNames?: {
    readonly root?: string
    readonly button?: string
  }
  readonly 'data-testid'?: string
}

export function FormSubmit({ label, children, classNames, 'data-testid': testId }: Props): React.ReactElement {
  const formik = useFormikContext()
  const t = useTranslatable()

  return (
    <div className={classNames?.root}>
      <button
        type="submit"
        className="cursor-pointer rounded-[4px] bg-seconderyRed px-4 py-2.5 text-white hover:opacity-80 disabled:cursor-default disabled:opacity-80"
        disabled={formik.isSubmitting || !formik.dirty}
        data-testid={testId}
      >
        {label ?? t('common:save')}
      </button>

      {children}
    </div>
  )
}
