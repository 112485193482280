export function EmailIcon(): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g id="mail_icon" transform="translate(-305 -415)">
        <g
          id="Rectangle_3664"
          data-name="Rectangle 3664"
          transform="translate(305 415)"
          stroke="#707070"
          className="fill-primaryWhite"
          strokeWidth="1"
          opacity="0"
        >
          <rect width="24" height="24" stroke="none" />
          <rect x="0.5" y="0.5" width="23" height="23" fill="none" />
        </g>
        <g
          id="Rectangle_4310"
          data-name="Rectangle 4310"
          transform="translate(305 418)"
          className="fill-none stroke-svgColor"
          strokeWidth="2"
        >
          <rect width="24" height="18" rx="3" stroke="none" />
          <rect x="1" y="1" width="22" height="16" rx="2" fill="none" />
        </g>
        <path
          id="Path_25084"
          data-name="Path 25084"
          d="M63.781,22.76,52.639,30.177,41.369,22.76"
          transform="translate(264.425 398.113)"
          className="fill-none stroke-svgColor"
          strokeWidth="2"
        />
      </g>
    </svg>
  )
}
