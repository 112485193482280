import React from 'react'
import { Link } from 'react-router-dom'
import { useWindowSize } from 'react-use'
import { ClockIcon } from 'src/assets/icons/customIcons/Clock'
import { shortenString } from 'src/helpers/fns'

interface Props {
  date: string
  title?: string
  to: string
  classNames?: {
    li?: string
    span?: string
  }
}

export const News = React.forwardRef<HTMLLIElement, JSX.IntrinsicElements['li'] & Props>(function News(
  { date, title, classNames, to, ...props },
  ref
): JSX.Element {
  const { width } = useWindowSize()

  let newsTitle = ''

  if (title != null) {
    if (width < 476) {
      newsTitle = title?.slice(0, 60) + '...'
    } else if (width < 576) {
      newsTitle = title?.slice(0, 100) + '...'
    } else {
      newsTitle = title
    }
  }

  return (
    <li {...props} ref={ref}>
      <Link
        to={to}
        className={`hover-left-animation flex h-[120px] w-full cursor-pointer flex-col items-start justify-center rounded-card bg-card px-6 py-1 hover:bg-hover-card xs:px-3 xs:py-2 ${
          classNames?.li ?? ''
        }`}
      >
        <span
          className={`mb-2 mr-[16px] max-h-[70px] overflow-y-scroll text-primaryTextColor ${classNames?.span ?? ''}`}
          data-testid="news/title"
        >
          {shortenString(newsTitle, 160)}
        </span>
        <div className="mr-3 flex items-center text-caption last:mr-0" data-testid="news/date">
          <ClockIcon />
          <span className="ml-2">{date}</span>
        </div>
      </Link>
    </li>
  )
})
