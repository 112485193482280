export function StatsIcon(): JSX.Element {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1346_15)">
        <path
          d="M20 1H4C2.34315 1 1 2.34315 1 4V20C1 21.6569 2.34315 23 4 23H20C21.6569 23 23 21.6569 23 20V4C23 2.34315 21.6569 1 20 1Z"
          stroke="#CCCCCC"
        />
        <path
          d="M11 7C11 6.44772 10.5523 6 10 6C9.44772 6 9 6.44772 9 7V17C9 17.5523 9.44772 18 10 18C10.5523 18 11 17.5523 11 17V7Z"
          fill="#CCCCCC"
        />
        <path
          d="M15 10C15 9.44772 14.5523 9 14 9C13.4477 9 13 9.44772 13 10V17C13 17.5523 13.4477 18 14 18C14.5523 18 15 17.5523 15 17V10Z"
          fill="#CCCCCC"
        />
        <path
          d="M7 14C7 13.4477 6.55228 13 6 13C5.44772 13 5 13.4477 5 14V17C5 17.5523 5.44772 18 6 18C6.55228 18 7 17.5523 7 17V14Z"
          fill="#CCCCCC"
        />
        <path
          d="M19 14C19 13.4477 18.5523 13 18 13C17.4477 13 17 13.4477 17 14V17C17 17.5523 17.4477 18 18 18C18.5523 18 19 17.5523 19 17V14Z"
          fill="#CCCCCC"
        />
      </g>
      <defs>
        <clipPath id="clip0_1346_15">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
