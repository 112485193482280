export default function PhpIcon(): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" data-testid="phpIcon">
      <g id="doc_icon" transform="translate(1 7)">
        <rect
          id="Rectangle_4360"
          data-name="Rectangle 4360"
          width="24"
          height="24"
          transform="translate(-1 -7)"
          opacity="0"
        />
        <rect
          id="Rectangle_4250"
          data-name="Rectangle 4250"
          width="22"
          height="22"
          transform="translate(0 -6)"
          opacity="0"
        />
        <g id="Path_25073" data-name="Path 25073" transform="translate(2 -6)" fill="#f2f2f2">
          <path d="M 17.5 21.5 L 0.5 21.5 L 0.5 0.5 L 17.5 0.5 L 17.5 21.5 Z" stroke="none" />
          <path d="M 1 1 L 1 21 L 17 21 L 17 1 L 1 1 M 0 0 L 18 0 L 18 22 L 0 22 L 0 0 Z" stroke="none" fill="gray" />
        </g>
        <g
          id="Rectangle_4275"
          data-name="Rectangle 4275"
          transform="translate(0 4)"
          fill="#f2f2f2"
          stroke="gray"
          strokeWidth="1"
        >
          <rect width="22" height="9" stroke="none" />
          <rect x="0.5" y="0.5" width="21" height="8" fill="none" />
        </g>
        <rect
          id="Rectangle_4361"
          data-name="Rectangle 4361"
          width="1"
          height="5"
          transform="translate(4 6)"
          fill="gray"
        />
        <rect
          id="Rectangle_4371"
          data-name="Rectangle 4371"
          width="1"
          height="5"
          transform="translate(14 6)"
          fill="gray"
        />
        <rect
          id="Rectangle_4364"
          data-name="Rectangle 4364"
          width="1"
          height="5"
          transform="translate(12 6)"
          fill="gray"
        />
        <rect
          id="Rectangle_4389"
          data-name="Rectangle 4389"
          width="1"
          height="5"
          transform="translate(9 6)"
          fill="gray"
        />
        <rect
          id="Rectangle_4365"
          data-name="Rectangle 4365"
          width="4"
          height="1"
          transform="translate(9 8)"
          fill="gray"
        />
        <g
          id="Rectangle_4362"
          data-name="Rectangle 4362"
          transform="translate(4 6)"
          fill="none"
          stroke="gray"
          strokeWidth="1"
        >
          <path d="M0,0H2A2,2,0,0,1,4,2V2A2,2,0,0,1,2,4H0A0,0,0,0,1,0,4V0A0,0,0,0,1,0,0Z" stroke="none" />
          <path
            d="M1,.5H2A1.5,1.5,0,0,1,3.5,2V2A1.5,1.5,0,0,1,2,3.5H1A.5.5,0,0,1,.5,3V1A.5.5,0,0,1,1,.5Z"
            fill="none"
          />
        </g>
        <g
          id="Rectangle_4370"
          data-name="Rectangle 4370"
          transform="translate(14 6)"
          fill="none"
          stroke="gray"
          strokeWidth="1"
        >
          <path d="M0,0H2A2,2,0,0,1,4,2V2A2,2,0,0,1,2,4H0A0,0,0,0,1,0,4V0A0,0,0,0,1,0,0Z" stroke="none" />
          <path
            d="M1,.5H2A1.5,1.5,0,0,1,3.5,2V2A1.5,1.5,0,0,1,2,3.5H1A.5.5,0,0,1,.5,3V1A.5.5,0,0,1,1,.5Z"
            fill="none"
          />
        </g>
        <g id="Group_283" data-name="Group 283" transform="translate(0.5 0.621)">
          <rect
            id="Rectangle_4390"
            data-name="Rectangle 4390"
            width="1"
            height="3"
            transform="translate(6.707 -0.293) rotate(-135)"
            fill="gray"
          />
          <rect
            id="Rectangle_4393"
            data-name="Rectangle 4393"
            width="1"
            height="3"
            transform="translate(14.293 -1.707) rotate(45)"
            fill="gray"
          />
          <rect
            id="Rectangle_4391"
            data-name="Rectangle 4391"
            width="1"
            height="3"
            transform="translate(6 -1) rotate(-45)"
            fill="gray"
          />
          <rect
            id="Rectangle_4392"
            data-name="Rectangle 4392"
            width="1"
            height="3"
            transform="translate(15 -1) rotate(135)"
            fill="gray"
          />
        </g>
      </g>
    </svg>
  )
}
