import * as React from 'react'
import { Helmet } from 'react-helmet-async'
// import { UploadConfigMap } from 'src/api/mediaFileUpload'
import { useTranslatable } from 'src/hooks/locale/utils'
import FormikTinyMce from 'src/views/components/forms/FormikTinyMce'
import { Form } from 'src/views/components/forms/formik/Form'
// import FileInput from 'src/views/components/forms/formik/FileInput'
// import { FileState } from '../../../state/FileInputModule'

export default function DevPage(): JSX.Element {
  const t = useTranslatable()

  return (
    <>
      <Helmet title={t('system:dev')} />
      <PageContent />
    </>
  )
}

function PageContent(): React.ReactElement | null {
  return (
    <Form
      initialValues={{ text: '' }}
      onSubmit={(values) => {
        console.info(values)
      }}
    >
      <FormikTinyMce name="text" />
    </Form>
  )
}
