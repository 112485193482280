import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import BackIcon from 'src/assets/icons/customIcons/BackIcon'
import { useTranslatable } from 'src/hooks/locale/utils'

interface Props {
  link?: string
}

export default function BackButton({ link }: Props): React.ReactElement {
  const t = useTranslatable()
  const navigate = useNavigate()

  return (
    <div data-testid="backButton">
      {link != null ? (
        <div>
          <a
            href={link}
            className="group relative mt-2 flex w-[100px] items-center justify-end rounded-full px-4 py-2 text-primaryTextColor duration-150 ease-in-out hover:bg-hover-card xxs:w-[60px] xxs:px-0 xs:w-[60px] xs:px-0"
          >
            <span className="absolute left-5 top-1/2 -translate-y-1/2 duration-150 ease-in-out group-hover:left-3 xxs:left-0 xs:left-0">
              <BackIcon />{' '}
            </span>
            {t('common:back')}
          </a>
        </div>
      ) : (
        <button
          className="group relative mt-2 flex w-[100px] items-center justify-end rounded-full px-4 py-2 text-primaryTextColor duration-150 ease-in-out hover:bg-hover-card xxs:w-[60px] xxs:px-0 xs:w-[60px] xs:px-0"
          type="button"
          onClick={() => navigate(-1)}
        >
          <span className="absolute left-5 top-1/2 -translate-y-1/2 duration-150 ease-in-out group-hover:left-3 xxs:left-0 xs:left-0">
            <BackIcon />{' '}
          </span>
          {t('common:back')}
        </button>
      )}
    </div>
  )
}
