export default function ProfileIcon(): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="stroke-borderGrey">
      <g id="profile_icon" transform="translate(-305 -415)">
        <g
          id="Rectangle_3664"
          data-name="Rectangle 3664"
          transform="translate(305 415)"
          fill="#fff"
          stroke="#707070"
          strokeWidth="1"
          opacity="0"
          className="stroke-borderGrey"
        >
          <rect width="24" height="24" stroke="none" />
          <rect x="0.5" y="0.5" width="23" height="23" fill="none" />
        </g>
        <g
          id="Ellipse_990"
          data-name="Ellipse 990"
          transform="translate(310 415)"
          fill="none"
          className="stroke-svgColor"
          strokeWidth="2"
        >
          <circle cx="7" cy="7" r="7" stroke="none" />
          <circle cx="7" cy="7" r="6" fill="none" />
        </g>
        <g
          id="Rectangle_4218"
          data-name="Rectangle 4218"
          transform="translate(307 427)"
          fill="none"
          strokeWidth="2"
          className="stroke-svgColor"
        >
          <path d="M8,0h4a8,8,0,0,1,8,8v4a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V8A8,8,0,0,1,8,0Z" stroke="none" />
          <path d="M8,1h4a7,7,0,0,1,7,7v2a1,1,0,0,1-1,1H2a1,1,0,0,1-1-1V8A7,7,0,0,1,8,1Z" fill="none" />
        </g>
      </g>
    </svg>
  )
}
