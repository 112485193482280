export function HelpTextIcon(): JSX.Element {
  return (
    <svg id="info_icon" xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" className="group">
      <g id="Ellipse_391" data-name="Ellipse 391" fill="none" stroke="gray" strokeWidth="1">
        <circle cx="7" cy="7" r="7" stroke="none" />
        <circle cx="7" cy="7" r="6.5" fill="none" className="duration-200 ease-in-out group-hover:fill-[#224DDB]" />
      </g>
      <path
        id="Path_25061"
        data-name="Path 25061"
        d="M2.517-2.284a.825.825,0,0,1,.608.243.81.81,0,0,1,.243.6.83.83,0,0,1-.243.6.814.814,0,0,1-.608.249.8.8,0,0,1-.6-.249.83.83,0,0,1-.243-.6.81.81,0,0,1,.243-.6A.81.81,0,0,1,2.517-2.284Z"
        transform="translate(4.207 11.921)"
        fill="gray"
        className="group-hover:fill-white"
      />
      <path
        id="Path_25062"
        data-name="Path 25062"
        d="M7.229,8.391h-1A2.884,2.884,0,0,0,6.2,8.049a1.769,1.769,0,0,1,.241-1.267,2.72,2.72,0,0,1,.88-.854,4.388,4.388,0,0,0,.687-.559.891.891,0,0,0,.305-.886,1.073,1.073,0,0,0-.72-.62,2.152,2.152,0,0,0-2.2.79l-.724-.69A3.115,3.115,0,0,1,7.755,2.875a2.059,2.059,0,0,1,1.507,1.28,1.825,1.825,0,0,1-.519,1.9l-.008.008a5.343,5.343,0,0,1-.837.687,1.836,1.836,0,0,0-.591.543.808.808,0,0,0-.109.644A3.789,3.789,0,0,1,7.229,8.391Z"
        fill="gray"
        className="group-hover:fill-white"
      />
    </svg>
  )
}
