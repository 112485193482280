import * as React from 'react'

type Props = React.HTMLAttributes<HTMLElement> & {
  readonly flag: string
}

export function FlagIcon(props: Props): React.ReactElement {
  let className = 'fi fi-' + props.flag

  if (props.className != null) {
    className += ' ' + props.className
  }

  return <i {...props} className={className} />
}
