import * as ProDuotoneSvgIcons from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { format, parseISO } from 'date-fns'
import * as React from 'react'
import { Helmet } from 'react-helmet-async'
import { useLocation, useSearchParams } from 'react-router-dom'
import * as Api from 'src/api'
import { useApi } from 'src/helpers/hooks'
import { useAuthenticatedHeaders } from 'src/hooks/auth/app'
import { useDateLocale, useTranslatable } from 'src/hooks/locale/utils'
import { useUserState, useUserStateMutateOnMount } from 'src/hooks/userState'
import Warning from 'src/imgs/classroom_icon.svg'
import { News } from 'src/tailwind/components/News'
import { Section } from 'src/tailwind/components/Section'
import { ErrorBoundary } from 'src/views/components/Error'
import ErrorElement from 'src/views/components/ErrorElement'
import Loader from 'src/views/components/Loader'
import NoContent from 'src/views/components/NoContent'
import Pagination from 'src/views/components/Pagination'

export default function NewsPage(): JSX.Element | null {
  const t = useTranslatable()

  return (
    <>
      <Helmet title={t('news:news')} />
      <React.Suspense fallback={<Loader className="m-auto flex" />}>
        <ErrorBoundary errorElement={<ErrorElement />}>
          <PageContent />
        </ErrorBoundary>
      </React.Suspense>
    </>
  )
}

function PageContent(): JSX.Element | null {
  const t = useTranslatable()
  const dateLocale = useDateLocale()
  const userState = useUserState()
  const [searchParams] = useSearchParams()
  const headers = useAuthenticatedHeaders()
  const page = searchParams.get('page')
  const perPage = searchParams.get('perPage')
  const { pathname } = useLocation()

  const fetching = useApi({
    endpoint: Api.getNews,
    params: React.useMemo(
      () => ({
        headers,
        query: {
          perPage: Number(perPage),
          page: Number(page) ?? 1,
        },
      }),
      [headers, page, perPage]
    ),
  })

  useUserStateMutateOnMount()

  const { data, meta } = fetching.data
  const pagination = <Pagination pagination={meta.pagination} />
  if (userState.data == null) return null

  const breadcrumbsItems = [{ page: `${t('news:news')}`, path: pathname }]

  return (
    <Section
      title={t('news:news')}
      icon={<FontAwesomeIcon icon={ProDuotoneSvgIcons.faBullhorn} />}
      breadcrubms={breadcrumbsItems}
    >
      {data != null && data.length > 0 ? (
        <>
          <div className="mb-4">{pagination}</div>
          {data.map((d) => (
            <div className="relative" key={d.id}>
              <News
                to={d.id}
                key={d.id}
                classNames={{ li: 'my-2', span: !d.userViewStatus?.isViewed ? 'font-semibold' : '' }}
                date={format(parseISO(d.createdAt), 'd MMMM yyyy, HH:mm', { locale: dateLocale })}
                title={d.title}
              />
              {!d.userViewStatus?.isViewed ? (
                <div className="absolute right-3 top-1/2 z-10 mx-auto my-0 size-3 rounded-full bg-[#519ED9]" />
              ) : null}
            </div>
          ))}
          <div className="mt-2">{pagination}</div>
        </>
      ) : (
        <NoContent header={t('error:empty_page')} image={Warning} subHeader={t('error:news_not_found')} />
      )}
    </Section>
  )
}
