import { useField } from 'formik'
import * as React from 'react'
import { useTranslatable } from 'src/hooks/locale/utils'
import { useFormData } from './Form'

interface Props {
  readonly className?: string
  readonly placeholder?: string
  readonly name: string
  readonly required?: boolean
  readonly disabled?: boolean
  readonly label?: string
  readonly rows?: number
  readonly cols?: number
}

export default function SwitchButton(props: Props): React.ReactElement {
  const t = useTranslatable()
  const [{ value, ...fieldProps }, meta, helpers] = useField<boolean>({
    name: props.name,
    validate(value: boolean) {
      if (props.required === true) {
        if (!value) {
          return props.label !== undefined ? `${props.label} ${t('common:is_required')}` : t('common:field_is_required')
        }
      }
    },
  })
  const [formData] = useFormData()
  const errors = formData.errors.fields[props.name] ?? []
  const isInvalid = (errors.length > 0 || meta.error != null) && meta.touched ? '!border-primaryRed' : ''
  const id = `label-${React.useId()}`

  return (
    <label className="relative inline-flex cursor-pointer items-center">
      <input
        type="checkbox"
        {...fieldProps}
        {...props}
        checked={value}
        onChange={() => void helpers.setValue(!value, true)}
        id={id}
        className={`peer sr-only ${props.className ?? ''} ${isInvalid}`}
      />
      <div className="peer h-5 w-9 rounded-full bg-gray-200 after:absolute after:left-[2px] after:top-[2px] after:size-4 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-blue-600 peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:border-gray-600 dark:bg-gray-700 dark:peer-focus:ring-blue-800" />
      {meta.touched && meta.error != null ? <div className="mt-2 text-sm text-errorMessages">{meta.error}</div> : null}

      {errors.map((error, i) => (
        <div key={i} className="mt-2 text-sm text-errorMessages">
          {error}
        </div>
      ))}
    </label>
  )
}
