export function LibraryIcon(): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 24 24">
      <g id="library_icon" transform="translate(-305 -415)">
        <g
          id="Rectangle_3664"
          data-name="Rectangle 3664"
          transform="translate(305 415)"
          className="fill-primaryWhite"
          stroke="#707070"
          strokeWidth="1"
          opacity="0"
        >
          <rect width="24" height="24" stroke="none" />
          <rect x="0.5" y="0.5" width="23" height="23" fill="none" />
        </g>
        <g
          id="Rectangle_3709"
          data-name="Rectangle 3709"
          transform="translate(316 418.978)"
          className="fill-primaryWhite stroke-svgColor"
          strokeWidth="2"
        >
          <rect width="13" height="20" rx="1" stroke="none" />
          <rect x="1" y="1" width="11" height="18" fill="none" />
        </g>
        <g
          id="Rectangle_3710"
          data-name="Rectangle 3710"
          transform="translate(305 418.978)"
          className="fill-primaryWhite stroke-svgColor"
          strokeWidth="2"
        >
          <rect width="13" height="20" rx="1" stroke="none" />
          <rect x="1" y="1" width="11" height="18" fill="none" />
        </g>
        <g id="Path_22449" data-name="Path 22449" transform="translate(316.1 418.978)" className="fill-primaryWhite">
          <path
            d="M 0.999997079372406 18.91496849060059 L 0.999997079372406 1.462199926376343 C 0.999997079372406 1.295479893684387 1.15218710899353 1.033549904823303 1.406877040863037 0.8837999105453491 C 1.836397647857666 0.631249725818634 7.257494926452637 -2.044503927230835 8.900276184082031 -2.850986480712891 L 8.900217056274414 14.76618003845215 C 8.900217056274414 14.88473033905029 8.900217056274414 15.03227996826172 8.535157203674316 15.22404003143311 C 8.223767280578613 15.387619972229 6.262466907501221 16.34349060058594 4.532057285308838 17.18684959411621 C 2.745321035385132 18.05764961242676 1.522588968276978 18.65410232543945 0.999997079372406 18.91496849060059 Z"
            stroke="none"
          />
          <path
            d="M 7.900217056274414 -1.244136810302734 C 5.787538051605225 -0.2019729614257812 2.673027038574219 1.341885566711426 1.999997138977051 1.699199676513672 L 1.999997138977051 17.30926513671875 C 2.571803569793701 17.02985954284668 3.274062633514404 16.6875171661377 4.093946933746338 16.28792953491211 C 5.543221473693848 15.5815954208374 7.305885314941406 14.72252368927002 7.900217056274414 14.42504024505615 L 7.900217056274414 -1.244136810302734 M 9.089959144592285 -3.977655410766602 C 9.565545082092285 -3.977655410766602 9.900217056274414 -3.450653076171875 9.900217056274414 -2.994649887084961 L 9.900217056274414 14.76618003845215 C 9.900217056274414 15.32147979736328 9.637247085571289 15.77468967437744 9.000197410583496 16.10932922363281 C 8.363137245178223 16.44397926330566 1.762077331542969 19.63790893554688 1.227697372436523 19.92070007324219 C 0.6933002471923828 20.2034912109375 -2.86102294921875e-06 19.68105888366699 -2.86102294921875e-06 19.1257495880127 L -2.86102294921875e-06 1.462200164794922 C -2.86102294921875e-06 0.9068899154663086 0.3656368255615234 0.3359794616699219 0.9000167846679688 0.02176952362060547 C 1.434407234191895 -0.2924404144287109 8.118467330932617 -3.582229614257812 8.761127471923828 -3.896440505981445 C 8.876055717468262 -3.952630996704102 8.986386299133301 -3.977655410766602 9.089959144592285 -3.977655410766602 Z"
            stroke="none"
            className="fill-svgColor"
          />
        </g>
        <g id="Path_22450" data-name="Path 22450" transform="translate(308 418.978)" className="fill-primaryWhite">
          <path
            d="M 8.900217056274414 18.91497230529785 C 8.377631187438965 18.65410614013672 7.154893398284912 18.05765151977539 5.368156909942627 17.18685150146484 C 3.637747049331665 16.34349060058594 1.676447153091431 15.38762092590332 1.365057110786438 15.22404098510742 C 0.999997079372406 15.03228092193604 0.999997079372406 14.88473129272461 0.999997079372406 14.76618099212646 L 0.999997079372406 -2.850956439971924 C 2.642778873443604 -2.044473648071289 8.063816070556641 0.6312506794929504 8.49333667755127 0.8838008642196655 C 8.748026847839355 1.03355085849762 8.900217056274414 1.295480847358704 8.900217056274414 1.462200880050659 L 8.900217056274414 18.91497230529785 Z"
            stroke="none"
          />
          <path
            d="M 1.999997138977051 -1.244136810302734 L 1.999997138977051 14.42504501342773 C 2.594322204589844 14.72252082824707 4.356992721557617 15.58159637451172 5.806267261505127 16.28793144226074 C 6.626151561737061 16.6875171661377 7.328413963317871 17.02986145019531 7.900217056274414 17.30926513671875 L 7.900217056274414 1.699200630187988 C 7.227187156677246 1.341886520385742 4.112668514251709 -0.2019748687744141 1.999997138977051 -1.244136810302734 M 0.8102550506591797 -3.977655410766602 C 0.9138278961181641 -3.977642059326172 1.024158477783203 -3.952630996704102 1.139086723327637 -3.896438598632812 C 1.781746864318848 -3.582229614257812 8.46580696105957 -0.2924385070800781 9.000197410583496 0.02177047729492188 C 9.534577369689941 0.3359804153442383 9.900217056274414 0.906890869140625 9.900217056274414 1.462201118469238 L 9.900217056274414 19.12575149536133 C 9.900217056274414 19.68106079101562 9.206907272338867 20.2034912109375 8.672516822814941 19.92070007324219 C 8.138136863708496 19.63791084289551 1.537076950073242 16.4439811706543 0.9000167846679688 16.10933113098145 C 0.2629671096801758 15.77469062805176 -2.86102294921875e-06 15.3214807510376 -2.86102294921875e-06 14.76618099212646 L -2.86102294921875e-06 -2.994649887084961 C -2.86102294921875e-06 -3.450651168823242 0.3346691131591797 -3.977712631225586 0.8102550506591797 -3.977655410766602 Z"
            stroke="none"
            className="fill-svgColor"
          />
        </g>
      </g>
    </svg>
  )
}
