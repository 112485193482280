export default function YamlIcon(): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" data-testid="yamlIcon">
      <g id="doc_icon" transform="translate(1 7)">
        <rect
          id="Rectangle_4360"
          data-name="Rectangle 4360"
          width="24"
          height="24"
          transform="translate(-1 -7)"
          opacity="0"
        />
        <rect
          id="Rectangle_4250"
          data-name="Rectangle 4250"
          width="22"
          height="22"
          transform="translate(0 -6)"
          opacity="0"
        />
        <g id="Path_25073" data-name="Path 25073" transform="translate(2 -6)" fill="#f2f2f2">
          <path
            d="M 17.5 21.5 L 0.5 21.5 L 0.5 0.5 L 11.79290008544922 0.5 L 17.5 6.207099914550781 L 17.5 21.5 Z"
            stroke="none"
          />
          <path
            d="M 1 1 L 1 21 L 17 21 L 17 6.414219856262207 L 11.58578014373779 1 L 1 1 M 0 0 L 12 0 L 18 6 L 18 22 L 0 22 L 0 0 Z"
            stroke="none"
            fill="gray"
          />
        </g>
        <g id="Path_25072" data-name="Path 25072" transform="translate(14 -6)" fill="none">
          <path d="M-1,0H0L6,6V7H-1Z" stroke="none" />
          <path
            d="M 0 1.414219856262207 L 0 6 L 4.585780143737793 6 L 0 1.414219856262207 M -1 0 L 0 0 L 6 6 L 6 7 L -1 7 L -1 0 Z"
            stroke="none"
            fill="gray"
          />
        </g>
        <g
          id="Rectangle_4275"
          data-name="Rectangle 4275"
          transform="translate(0 4)"
          fill="#f2f2f2"
          stroke="gray"
          strokeWidth="1"
        >
          <rect width="23" height="9" stroke="none" />
          <rect x="0.5" y="0.5" width="22" height="8" fill="none" />
        </g>
        <rect
          id="Rectangle_4405"
          data-name="Rectangle 4405"
          width="1"
          height="2"
          transform="translate(4 9)"
          fill="gray"
        />
        <rect
          id="Rectangle_4406"
          data-name="Rectangle 4406"
          width="3"
          height="1"
          transform="translate(7 9)"
          fill="gray"
        />
        <path id="Path_25111" data-name="Path 25111" d="M0,0H1L3,3H2Z" transform="translate(2 6)" fill="gray" />
        <path id="Path_25114" data-name="Path 25114" d="M0,5H1L3,0H2Z" transform="translate(6 6)" fill="gray" />
        <path id="Path_25112" data-name="Path 25112" d="M3,0H2L0,3H1Z" transform="translate(4 6)" fill="gray" />
        <path id="Path_25113" data-name="Path 25113" d="M3,5H2L0,0H1Z" transform="translate(8 6)" fill="gray" />
        <rect
          id="Rectangle_4407"
          data-name="Rectangle 4407"
          width="1"
          height="5"
          transform="translate(15 6)"
          fill="gray"
        />
        <rect
          id="Rectangle_4408"
          data-name="Rectangle 4408"
          width="1"
          height="5"
          transform="translate(11 6)"
          fill="gray"
        />
        <path id="Path_25115" data-name="Path 25115" d="M0,0H1L3,4H2Z" transform="translate(11 6)" fill="gray" />
        <path id="Path_25116" data-name="Path 25116" d="M3,0H2L0,4H1Z" transform="translate(13 6)" fill="gray" />
        <rect
          id="Rectangle_4409"
          data-name="Rectangle 4409"
          width="1"
          height="5"
          transform="translate(17 6)"
          fill="gray"
        />
        <rect
          id="Rectangle_4410"
          data-name="Rectangle 4410"
          width="4"
          height="1"
          transform="translate(17 10)"
          fill="gray"
        />
      </g>
    </svg>
  )
}
