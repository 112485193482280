import * as React from 'react'
import { Helmet } from 'react-helmet-async'
import { useParams } from 'react-router-dom'
import * as Api from 'src/api'
import { useApi } from 'src/helpers/hooks'
import { useAuthenticatedHeaders } from 'src/hooks/auth/app'
import { useTranslatable } from 'src/hooks/locale/utils'

import { ErrorBoundary } from 'src/views/components/Error'
import ErrorElement from 'src/views/components/ErrorElement'
import Loader from '../../components/Loader'

export default function MediaFilePage(): React.ReactElement | null {
  const t = useTranslatable()

  return (
    <>
      <Helmet title={t('file:file_download')} />
      <React.Suspense fallback={<Loader className="mx-auto my-10 flex" />}>
        <ErrorBoundary errorElement={<ErrorElement />}>
          <PageContent />
        </ErrorBoundary>
      </React.Suspense>
    </>
  )
}

function PageContent(): React.ReactElement | null {
  const { id } = useParams()
  const headers = useAuthenticatedHeaders()

  const mediaFile = useApi({
    endpoint: Api.getMediaFilesDownload,
    params: React.useMemo(
      () => ({
        headers,
        args: {
          id: id!,
        },
      }),
      [headers, id]
    ),
  })

  location.replace(mediaFile.data.url)

  return null
}
