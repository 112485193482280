interface Props {
  dataTestid?: string
}
export default function InfoIcon({ dataTestid = 'infoIcon' }: Props): JSX.Element {
  return (
    <svg
      id="info_icon"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      data-testid={dataTestid}
    >
      <rect id="Rectangle_3629" data-name="Rectangle 3629" width="24" height="24" opacity="0" />
      <rect
        id="Rectangle_3630"
        data-name="Rectangle 3630"
        width="22"
        height="22"
        transform="translate(1 1)"
        opacity="0"
      />
      <g
        id="Ellipse_1001"
        data-name="Ellipse 1001"
        transform="translate(1 1)"
        fill="none"
        stroke="gray"
        strokeWidth="1"
      >
        <circle cx="11" cy="11" r="11" stroke="none" />
        <circle cx="11" cy="11" r="10.5" fill="none" />
      </g>
      <rect
        id="Rectangle_4259"
        data-name="Rectangle 4259"
        width="3"
        height="1"
        transform="translate(10 10)"
        fill="gray"
      />
      <rect
        id="Rectangle_4260"
        data-name="Rectangle 4260"
        width="5"
        height="1"
        transform="translate(10 16)"
        fill="gray"
      />
      <rect
        id="Rectangle_4261"
        data-name="Rectangle 4261"
        width="1"
        height="7"
        transform="translate(12 10)"
        fill="gray"
      />
      <circle id="Ellipse_1002" data-name="Ellipse 1002" cx="1" cy="1" r="1" transform="translate(11 7)" fill="gray" />
    </svg>
  )
}
