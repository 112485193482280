import { t } from 'i18next'
import React, { Dispatch, SetStateAction } from 'react'
import { useParams } from 'react-router-dom'
import * as Api from 'src/api'
import { UserIcon } from 'src/assets/icons/customIcons/menu-icons/User'
import { useApi } from 'src/helpers/hooks'
import { useAuthenticatedHeaders } from 'src/hooks/auth/app'
import { Modal } from 'src/tailwind/components/Modal'
import * as Table from 'src/tailwind/components/Table'
import Loader from 'src/views/components/Loader'

interface PopupProps {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  semester?: number
  userName: string
}

export default function MessageInfoPopup({ open, setOpen, semester, userName }: PopupProps) {
  const headers = useAuthenticatedHeaders()
  const { messageId, semesterId } = useParams()

  const studentInfo = useApi({
    endpoint: Api.getStudentByMessage,
    params: React.useMemo(
      () => ({
        headers,
        query: {
          semId: semesterId!,
        },
        args: {
          messageId: messageId!,
          semId: semesterId!,
        },
      }),
      [headers, messageId, semesterId]
    ),
  })

  const studentCursInfo = studentInfo?.data.groups ? studentInfo?.data.groups[0]?.course : ''

  return (
    <Modal
      title={t('resumeLayout:personal_information')}
      className="notify-modal"
      isModalOpen={open}
      onClose={() => setOpen(false)}
      icon={<UserIcon />}
    >
      <h2 className="">{t('course:student_profile')}</h2>
      <div className="ml-2 flex">
        <div className="mr-5 text-end font-bold">
          <p>{t('uncategorized:name_surname')}</p>
          <p>{t('person:degree')}</p>
          <p>{t('faculty:faculty_name')}</p>
          <p>{t('program:programme')}</p>
          <p>{t('common:academic_semester')}</p>
        </div>
        <div className="align-start">
          <p>{userName}</p>
          <p> {studentCursInfo ? t(`degrees:${studentCursInfo.degree}`) : ''}</p>
          <p>{studentInfo.data.profilePrograms?.faculty.name}</p>
          <p>{studentInfo.data.profilePrograms?.customProgram.name}</p>
          <p>{semester ?? ''}</p>
        </div>
      </div>
      <h2 className="mb-2 mt-5">{t('notification:your_subjects_chosen_by_given_student')}</h2>
      <table>
        <Table.Tr className="student-table-mess">
          <Table.Td colSpan={6} className="bg-card !p-0">
            <Table.Table className="!rounded-none">
              <Table.Thead>
                <Table.Tr className="border-t-0">
                  <Table.Th className="w-[70%] !py-[10px]">{t('common:name')}</Table.Th>
                  <Table.Th className="w-[16%] !py-[10px]">{t('common:code')}</Table.Th>
                  <Table.Th className="w-[16%] !py-[10px]">{t('group:group')}</Table.Th>
                  <Table.Th className="w-[16%] !py-[10px]">{t('course:credit')}</Table.Th>
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>
                {studentCursInfo == null && !studentInfo.data && (
                  <Table.Tr className="absolute left-[47%] top-[46%] border-none">
                    <Table.Td className="border-none">
                      <Loader />
                    </Table.Td>
                  </Table.Tr>
                )}
                {studentInfo?.data.groups?.length === 0 && (
                  <Table.Tr className="border-none bg-none text-right font-bold hover:bg-none">
                    <Table.Td className="border-none">{t('schedule:schedule_is_empty')}</Table.Td>
                  </Table.Tr>
                )}
                {studentInfo?.data?.groups &&
                  studentInfo?.data?.groups.map((group, index) => (
                    <React.Fragment key={index}>
                      <Table.Tr>
                        <Table.Td className="!py-[10px]">{group.course?.fullName}</Table.Td>
                        <Table.Td className="!py-[10px]">{group.course?.code}</Table.Td>
                        <Table.Td className="!py-[10px]">{group.name}</Table.Td>
                        <Table.Td className="!py-[10px]">{group.course?.credits}</Table.Td>
                      </Table.Tr>
                    </React.Fragment>
                  ))}
              </Table.Tbody>
            </Table.Table>
          </Table.Td>
        </Table.Tr>
      </table>
    </Modal>
  )
}
